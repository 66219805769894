import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import YouTubeIcon from "@mui/icons-material/YouTube";

import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Typography,
    styled
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Editor from "react-simple-wysiwyg";
import ImageUpload from "../../../components/image";
import NormalInput from "../../../components/inputs/normalInput";
import NumberInput from "../../../components/inputs/normalInput/number";
import CustomTextArea from "../../../components/inputs/textarea";
import BasicSelect from "../../../components/select";
import CustomToolTip from "../../../components/tootlip";
import Schema from "../../../schema";
import { DELIVERABLES_DATA, fileToBase64, formatDate, isEmptyObject, removeHtmltags } from "../../../utils/common";
import {
    CAMPAIGN_TYPES,
    DRAFT,
    LIVE,
    phonePreventText
} from "../../../utils/constant";
import { getAllBrandForDropDown } from "../../brands/action";
import { addNewCampaign, getPresignedurl } from "../action";
import { updateCampaign } from "../campaignSlice";
import ProgressBars from "./ProgressBar";

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '.MuiAccordionSummary-expandIcon': {
        transform: 'none',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
        transform: 'none !important',
    },
}));
const MobileComponent = styled("div")({
    label: {
        fontSize: "10px",
    },
    input: {
        fontSize: "13px !important",
        width: "100%",
        height: "5px",
    },
    textArea: {
        fontSize: "13px !important",
        width: "100%",
        height: "50%",
    },
});
const Info = () => {
    const videoRef = useRef();
    const [paused, setPaused] = useState(true);
    const [bar, setBar] = useState({ deliverablesSection: 0, addtionalSection: 0, detailsSection: 0 });
    const [bannerPreview, setBannerPreview] = useState(null);
    const handleVideo = () => {
        if (paused) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
        setPaused(!paused);
    };


    const navigate = useNavigate();
    const { formData, productDropdown, referenceContentUrl, deliverablesSection, detailsSection, addtionalSection, campaignAppliedCount } = useSelector(
        (state) => state.campaignSlice
    );



    const { brandsDropdown } = useSelector((state) => state.brandSlice);
    const { globalLoader } = useSelector((state) => state.globalLoaderSlice);

    const dispatch = useDispatch();

    const deliverables = DELIVERABLES_DATA;

    useEffect(() => {
        dispatch(getAllBrandForDropDown(formData.userId));
        window.onbeforeunload = () => true;

        return () => {
            window.onbeforeunload = null;
        };
    }, [formData]);


    console.log("campaignAppliedCount", campaignAppliedCount)

    useEffect(() => {
        if (formData?.mode === "edit") {
            dispatch(
                updateCampaign({
                    productDropdown: brandsDropdown?.find(
                        (v) => v.id === formData.brandId
                    )?.product,
                })
            );
        }
    }, [formData, brandsDropdown]);

    useEffect(() => {
        if (formData?.mode === "edit") {
            let deliverablesCompleted = formData?.selectedDeliverables?.filter((v) => v?.post !== '' && v?.qty > 0)?.length > 0 ? 1 : 0
            let additionalCompleted = formData.contactDetails ? 1 : 0
            let detailsCompleted = [formData.name, formData?.brandId, formData?.startDate, formData?.endDate, formData?.bannerImage, formData?.brief, formData?.contentSubmissionDate, formData?.contentPublishDate, formData?.budgetPerInfluencer, formData?.noOfCreators].filter((v, i) => {
                return v === "";
            })?.length == 0 && formData.products?.filter((v) => v.name !== '' && v.link !== '')?.length > 0 ? 1 : 0;
            dispatch(updateCampaign({ deliverablesSection: deliverablesCompleted, addtionalSection: additionalCompleted, detailsSection: detailsCompleted }));
        }
    }, [])

    let initialValues = {
        name: formData?.name || "",
        brandId: formData?.brandId || "",
        products: formData?.products || [{ name: "", link: "" }],
        platform: formData?.platform || "",
        deliverables: formData?.deliverables || [],
        selectedDeliverables: formData?.selectedDeliverables || [{ post: "", qty: 0, type: "" }],
        startDate: formData?.status === DRAFT && new Date(formData?.startDate) <= new Date() ? formatDate(new Date()) : (formData?.startDate || ""),
        endDate: formData?.endDate || "",
        bannerImage: isEmptyObject(formData?.bannerImageBase64) || formData?.bannerImage || "",
        brief: formData?.brief || "",
        conceptScriptDate: formData?.conceptScriptDate || "",
        contentSubmissionDate: formData?.contentSubmissionDate || "",
        contentPublishDate: formData?.contentPublishDate || "",
        productShipmentDate: formData?.productShipmentDate || "",
        budget: formData?.budget || "",
        budgetPerInfluencer: formData?.budgetPerInfluencer || "",
        noOfCreators: formData?.noOfCreators || "",
        accountMentions: formData?.accountMentions || "",
        campaignHashtag: formData?.campaignHashtag || "",
        referenceContent: formData?.referenceContent || "",
        referenceContentUrl: formData?.referenceContentUrl || "",
        contactDetails: formData?.contactDetails || "",
        post: formData?.post || [],
        type: formData.type,
        demand: formData.demand,
        brandLogo: "",
        brandThumbnail: "",
        additionalRequirement: "",
        kpi: [],
        shipmentDetails: formData.shipmentDetails || [], // nee
        applyBydate: formData?.applyBydate ? formatDate(formData?.applyBydate) : "",
        advancePayment: formData?.advancePayment,
        scriptApproval: formData?.scriptApproval,
        contentApproval: formData?.contentApproval,
        linkApproval: formData?.linkApproval,
        totalMilestone: 0
    };
    console.log("applied", formData)
    return (
        <>
            <Stack direction={"column"} gap={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: "42px",
                    }}
                >
                    <Typography className="page-title" variant="h1">
                        {formData?.mode == "add" ? "Create New Campaign" : "Edit Campaign"}
                    </Typography>
                    {/* ProgressBars */}
                    <ProgressBars bar={bar} />
                </Stack>
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        transition: "all .5s ease-in-out",
                        position: "relative",
                        border: "1px solid rgba(170, 170, 170, 0.3)",
                        borderRadius: "12px",
                        boxShadow: "0px 5px 5px 0px rgba(82, 63, 105, 0.05)",
                        overflow: "hidden",
                    }}
                >
                    <Formik
                        initialValues={initialValues}
                        //enableReinitialize
                        validateOnChange={true}
                        validateOnBlur={true}
                        validationSchema={Schema.CampaignInfo}
                        onSubmit={(values, { setSubmitting }) => {
                            // values.selectedDeliverables = values?.selectedDeliverables?.filter((v) => v)?.filter((v) => v?.qty > 0);
                            dispatch(
                                updateCampaign({
                                    formData: { ...formData, ...values, campaignApplyingPreferences: formData?.campaignApplyingPreferences || [] }
                                })
                            );
                            if (formData?.campaignId) {
                                values.campaignId = formData?.campaignId;
                            }
                            // dispatch(addCampaignInfo(values, endpoints.addCampaign));
                            navigate("/campaign/profile-screener");
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {

                            console.log("errors", errors);
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <Grid container>
                                        <Grid
                                            xs={8}
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            p={3}
                                            sx={{ borderRight: "1px solid #D9D9D9" }}
                                        >


                                            <Grid item mb={2}>
                                                <Accordion sx={{ bgcolor: "#f7f7f7" }}>
                                                    <CustomAccordionSummary
                                                        expandIcon={<Chip color={values.platform !== '' && values?.selectedDeliverables?.filter((v) => v?.post !== '' && v?.qty > 0)?.length > 0 ? "success" : "error"} variant="outlined" label={values.platform !== '' && values?.selectedDeliverables?.filter((v) => v?.post !== '' && v?.qty > 0)?.length > 0 ? "DONE" : "EDIT"} sx={{ borderRadius: 1 }} size="medium" />}

                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Typography variant="h4" component="h6">
                                                            Select Platform & Deliverables
                                                        </Typography>
                                                    </CustomAccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container item spacing={3} lg={12} md={12} sm={12}>
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <Typography variant="subtitle1" component="subtitle1">
                                                                    Select a platform for your campaign.
                                                                </Typography>
                                                                <Stack className="campBtns" direction={"row"} gap={2} mt={2}>
                                                                    <button
                                                                        className={
                                                                            values?.platform === "instagram"
                                                                                ? "btnPrimary"
                                                                                : "btnSecondary"
                                                                        }
                                                                        disabled={formData?.status === LIVE}

                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "platform",
                                                                                    value: "instagram",
                                                                                },
                                                                            });
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "deliverables",
                                                                                    value: deliverables.find(
                                                                                        (v) => v["instagram"]
                                                                                    ).instagram,
                                                                                },
                                                                            });
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "selectedDeliverables",
                                                                                    value: [],
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        <InstagramIcon size="small" color="error" />
                                                                        Instagram
                                                                    </button>
                                                                    <button
                                                                        className={
                                                                            values?.platform === "facebook"
                                                                                ? "btnPrimary"
                                                                                : "btnSecondary"
                                                                        }
                                                                        disabled={formData?.status === LIVE}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "platform",
                                                                                    value: "facebook",
                                                                                },
                                                                            });
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "deliverables",
                                                                                    value: deliverables.find((v) => v["facebook"])
                                                                                        .facebook,
                                                                                },
                                                                            });
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "selectedDeliverables",
                                                                                    value: [],
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <FacebookOutlinedIcon
                                                                            size="small"
                                                                            color="primary"
                                                                        />
                                                                        Facebook
                                                                    </button>
                                                                    <button
                                                                        className={
                                                                            values?.platform === "youtube"
                                                                                ? "btnPrimary"
                                                                                : "btnSecondary"
                                                                        }
                                                                        disabled={formData?.status === LIVE}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "platform",
                                                                                    value: "youtube",
                                                                                },
                                                                            });
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "deliverables",
                                                                                    value: deliverables.find((v) => v["youtube"])
                                                                                        .youtube,
                                                                                },
                                                                            });
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "selectedDeliverables",
                                                                                    value: [],
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        <YouTubeIcon size="small" color="error" />
                                                                        Youtube
                                                                    </button>
                                                                </Stack>

                                                                {errors.platform && touched.platform && (
                                                                    <p className="error m-0" style={{ paddingTop: 8, paddingBottom: 8 }}>{`${errors?.platform}`}</p>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12} mb={2}>
                                                                <Typography variant="h4" component="h6">
                                                                    Number of content
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    gutterBottom
                                                                    mt={1}
                                                                >
                                                                    Please specify the number of Instagram posts
                                                                    you expect from each influencer.
                                                                </Typography>
                                                            </Grid>

                                                            <FieldArray
                                                                name={`selectedDeliverables`}
                                                                key={values.selectedDeliverables?.length}
                                                                render={(array) => (
                                                                    <>
                                                                        {values?.deliverables?.map((v, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={2}
                                                                                        lg={2}
                                                                                        md={2}
                                                                                        sm={2}
                                                                                    >
                                                                                        <Stack direction={"row"} gap={4} mt={1}>
                                                                                            {values.platform && DELIVERABLES_DATA.find((v) => v[values.platform])[values.platform][index]['icon']}

                                                                                            <FormLabel>{v.name}</FormLabel>
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={1}
                                                                                        lg={1}
                                                                                        md={1}
                                                                                        sm={1}
                                                                                    ></Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={2}
                                                                                        lg={2}
                                                                                        md={2}
                                                                                        sm={2}

                                                                                    >
                                                                                        <Select
                                                                                            key={values.selectedDeliverables.length}
                                                                                            labelId="demo-simple-select-label"
                                                                                            id="demo-simple-select"
                                                                                            required={false}
                                                                                            defaultValue={
                                                                                                values?.selectedDeliverables[
                                                                                                    index
                                                                                                ]?.post || ""
                                                                                            }
                                                                                            value={
                                                                                                values?.selectedDeliverables[
                                                                                                    index
                                                                                                ]?.post || ""
                                                                                            }
                                                                                            disabled={formData?.status === LIVE && campaignAppliedCount?.length > 0}
                                                                                            name={`selectedDeliverables.[${index}].post`}
                                                                                            label=""
                                                                                            onChange={(e) => {
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: `selectedDeliverables.[${index}].post`,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: `selectedDeliverables.[${index}].type`,
                                                                                                        value: v.name.toLowerCase(),
                                                                                                    },
                                                                                                });
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: `selectedDeliverables.[${index}].qty`,
                                                                                                        value: 0,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                            native
                                                                                        >
                                                                                            <option value="" disabled>Select</option>
                                                                                            {v.dropDownvalue?.map(
                                                                                                (v, i) => {
                                                                                                    return (
                                                                                                        <option value={v} key={v}>
                                                                                                            {v}
                                                                                                        </option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </Select>
                                                                                        {/* <BasicSelect
                                                                                            // key={values.platform}
                                                                                            required={false}
                                                                                            name={`selectedDeliverables.[${index}].post`}
                                                                                            label={""}
                                                                                            defaultValue={
                                                                                                values?.selectedDeliverables[
                                                                                                    index
                                                                                                ]?.post || ""
                                                                                            }
                                                                                            value={
                                                                                                values?.selectedDeliverables[
                                                                                                    index
                                                                                                ]?.post || ""
                                                                                            }
                                                                                            handleChange={(e) => {
                                                                                                e.preventDefault();
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            options={v.dropDownvalue?.map(
                                                                                                (v, i) => {
                                                                                                    return (
                                                                                                        <option value={v} key={v}>
                                                                                                            {v}
                                                                                                        </option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        /> */}

                                                                                        <Field
                                                                                            name={`selectedDeliverables.[${index}].post`}
                                                                                            render={({ form }) => {
                                                                                                const error = getIn(
                                                                                                    form.errors,
                                                                                                    `selectedDeliverables.[${index}].post`
                                                                                                );
                                                                                                const touch = getIn(
                                                                                                    form.touched,
                                                                                                    `selectedDeliverables.[${index}].post`
                                                                                                );
                                                                                                return touch && error ? (
                                                                                                    <p className="error">
                                                                                                        {error}
                                                                                                    </p>
                                                                                                ) : null;
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={1}
                                                                                        lg={1}
                                                                                        md={1}
                                                                                        sm={1}
                                                                                    ></Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={6}
                                                                                        lg={6}
                                                                                        md={6}
                                                                                        sm={6}
                                                                                    >
                                                                                        <Stack
                                                                                            direction={"row"}
                                                                                            gap={4}
                                                                                            ml={2}
                                                                                            mt={1}
                                                                                        >
                                                                                            <AddCircleIcon
                                                                                                key={values.selectedDeliverables.length}
                                                                                                color="success"
                                                                                                className="showhandCursor"
                                                                                                onClick={() => {
                                                                                                    const currentQty =
                                                                                                        parseInt(
                                                                                                            values
                                                                                                                ?.selectedDeliverables[
                                                                                                                index
                                                                                                            ]?.qty
                                                                                                        ) || 0;
                                                                                                    handleChange({
                                                                                                        target: {
                                                                                                            name: `selectedDeliverables.[${index}].qty`,
                                                                                                            value: currentQty + 1,
                                                                                                        },
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                            <span>
                                                                                                {values?.selectedDeliverables[
                                                                                                    index
                                                                                                ]?.qty || 0}
                                                                                            </span>
                                                                                            <RemoveCircleIcon
                                                                                                className="showhandCursor"
                                                                                                // disabled={formData?.status === LIVE && campaignAppliedCount?.length > 0}
                                                                                                color={
                                                                                                    values?.selectedDeliverables !== undefined && parseInt(values?.selectedDeliverables[
                                                                                                        index
                                                                                                    ]?.qty) === 0
                                                                                                        ? "disabled"
                                                                                                        : (formData?.status === LIVE && campaignAppliedCount?.length > 0 ? "disabled" : "error")
                                                                                                }
                                                                                                onClick={(e) => {
                                                                                                    if (formData?.status === LIVE && campaignAppliedCount?.length > 0) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    if (
                                                                                                        !parseInt(
                                                                                                            values?.selectedDeliverables[
                                                                                                                index
                                                                                                            ]?.qty
                                                                                                        )
                                                                                                    ) {
                                                                                                        return false;
                                                                                                    }


                                                                                                    const currentQty =
                                                                                                        parseInt(
                                                                                                            values
                                                                                                                ?.selectedDeliverables[
                                                                                                                index
                                                                                                            ]?.qty
                                                                                                        ) || 0;
                                                                                                    handleChange({
                                                                                                        target: {
                                                                                                            name: `selectedDeliverables.[${index}].qty`,
                                                                                                            value: currentQty - 1,
                                                                                                        },
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                        <Field
                                                                                            name={`selectedDeliverables.[${index}].qty`}
                                                                                            render={({ form }) => {
                                                                                                const error = getIn(
                                                                                                    form.errors,
                                                                                                    `selectedDeliverables.[${index}].qty`
                                                                                                );
                                                                                                const touch = getIn(
                                                                                                    form.touched,
                                                                                                    `selectedDeliverables.[${index}].qty`
                                                                                                );
                                                                                                return touch && error ? (
                                                                                                    <p className="error">
                                                                                                        {error}
                                                                                                    </p>
                                                                                                ) : null;
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </>
                                                                )}
                                                            />
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                {errors.selectedDeliverables && (
                                                                    <p className="error m-0">
                                                                        {`${errors?.selectedDeliverables}`}
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                            {values?.platform !== "youtube" &&
                                                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                    <FormGroup
                                                                        sx={{ mt: 2, gap: 3 }}
                                                                        row
                                                                        rowGap={10}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={values?.post?.includes("collabPost")}
                                                                                    onChange={handleChange}
                                                                                    name="post"
                                                                                    value="collabPost"
                                                                                />
                                                                            }
                                                                            label="This is a Collab Post"
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={values?.post?.includes("bostPost")}
                                                                                    onChange={handleChange}
                                                                                    name="post"
                                                                                    value="bostPost"
                                                                                />
                                                                            }
                                                                            label="Access to boost this post"
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>}
                                                            {[CAMPAIGN_TYPES.PAID, CAMPAIGN_TYPES.BARTER].includes(formData.type) &&
                                                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                    <FormGroup
                                                                        sx={{ gap: 3 }}
                                                                        row
                                                                        rowGap={10}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={values?.shipmentDetails?.includes("shipmentDetails")}
                                                                                    onChange={handleChange}
                                                                                    name="shipmentDetails"
                                                                                    value="shipmentDetails"
                                                                                />
                                                                            }
                                                                            label="Product shipment details needed."
                                                                        />

                                                                    </FormGroup>
                                                                </Grid>}
                                                            <Grid
                                                                item

                                                                xs={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >

                                                                <button
                                                                    className="btnPrimary"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let shipmentDetails = values?.shipmentDetails?.length > 0 ? 1 : 0
                                                                        let delivarables = {
                                                                            name: values.name || "Draft",
                                                                            shipmentDetails: shipmentDetails,
                                                                            deliverables: JSON.stringify([{ plateform: values.platform, deliverables: values?.selectedDeliverables?.filter((v) => v != null)?.filter((v) => v?.qty > 0), post: values.post }]),
                                                                        }
                                                                        let deliverablesCompleted = values?.selectedDeliverables?.filter((v) => v?.post && v?.qty)?.length > 0 ? 1 : 0;



                                                                        dispatch(updateCampaign({ deliverablesSection: deliverablesCompleted, formData: { ...formData, ...values, shipmentDetails: shipmentDetails } }));
                                                                        setBar((prev) => ({ ...prev, deliverablesSection: deliverablesCompleted }))
                                                                        //  dispatch(updateCampaign({ progressBar: detailsCompleted + deliverablesCompleted + additonalCompleted }));
                                                                        dispatch(addNewCampaign(delivarables, navigate));
                                                                    }}
                                                                >
                                                                    Done
                                                                </button>

                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            <Grid item mb={2}>
                                                <Accordion sx={{ bgcolor: "#f7f7f7" }}>
                                                    <CustomAccordionSummary
                                                        expandIcon={<Chip color={[values.name, values?.brandId, values?.startDate, values?.endDate, values?.bannerImage, values?.brief, values?.contentSubmissionDate, values?.contentPublishDate, (formData.type === "barter" ? "barter" : values?.budget), values?.budgetPerInfluencer, values?.noOfCreators, values.applyBydate].filter((v, i) => {
                                                            return v === "";
                                                        })?.length == 0 && values.products?.filter((v) => v.name !== '' && v.link !== '')?.length > 0 ? "success" : "error"} variant="outlined" label={[values.name, values?.brandId, values?.startDate, values?.endDate, values?.bannerImage, values?.brief, values?.contentSubmissionDate, values?.contentPublishDate, (formData.type === "barter" ? "barter" : values?.budget), values?.budgetPerInfluencer, values?.noOfCreators, values.applyBydate].filter((v, i) => {

                                                            return v === "";
                                                        })?.length == 0 && values.products?.filter((v) => v.name !== '' && v.link !== '')?.length > 0 ? "DONE" : "EDIT"} sx={{ borderRadius: 1 }} size="medium" />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Typography variant="h4" component="h6">
                                                            Campaign Details & Budget
                                                        </Typography>
                                                    </CustomAccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3} lg={12} md={12} sm={12}>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>Campaign Name</FormLabel>
                                                                <NormalInput
                                                                    name={"name"}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    isRequired={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.name) && errors.name}
                                                                    helperText={touched.name && errors.name}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>Brand Name</FormLabel>
                                                                <BasicSelect
                                                                    required={false}
                                                                    name={"brandId"}
                                                                    label={""}
                                                                    values={values.brandId}
                                                                    disabled={formData?.status === LIVE}
                                                                    handleChange={(e) => {
                                                                        handleChange(e);
                                                                        handleChange({
                                                                            target: {
                                                                                name: "productName",
                                                                                value: "",
                                                                            },
                                                                        });
                                                                        handleChange({
                                                                            target: {
                                                                                name: "products",
                                                                                value: [{ name: "", link: "" }],
                                                                            },
                                                                        });
                                                                        //
                                                                        dispatch(
                                                                            updateCampaign({
                                                                                productDropdown: brandsDropdown?.find(
                                                                                    (item) => item.id === e.target.value
                                                                                )?.product,
                                                                            })
                                                                        );

                                                                        setFieldValue(
                                                                            "brandName",
                                                                            brandsDropdown.find(
                                                                                (item) => item.id === e.target.value
                                                                            )?.brandName
                                                                        );
                                                                    }}
                                                                    options={brandsDropdown?.map((item) => {
                                                                        return (
                                                                            <MenuItem value={item.id} key={item.id}>
                                                                                {item.brandName}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                />
                                                                {errors.brandId && touched.brandId && (
                                                                    <p className="error m-0">
                                                                        {`${errors?.brandId}`}
                                                                    </p>
                                                                )}
                                                            </Grid>

                                                            <FieldArray
                                                                name={`products`}
                                                                key={productDropdown?.length}
                                                                render={(array) => (
                                                                    <>
                                                                        {values?.products?.map((post, index) => (
                                                                            <>
                                                                                <Grid item xs={12} lg={6} md={6} sm={6}>
                                                                                    <FormLabel required>
                                                                                        Product Name-{index + 1}
                                                                                    </FormLabel>
                                                                                    <BasicSelect
                                                                                        required={false}
                                                                                        name={`products.[${index}].name`}
                                                                                        label={""}
                                                                                        disabled={formData?.status === LIVE}
                                                                                        //key={step + brandsDropdown.length > 0}
                                                                                        defaultValue={
                                                                                            values.products[index].name
                                                                                        }
                                                                                        // disabled={values.brandId === ""}
                                                                                        values={values.products[index].name}
                                                                                        handleChange={handleChange}
                                                                                        options={productDropdown?.map(
                                                                                            (item, index) => {
                                                                                                return (
                                                                                                    <MenuItem
                                                                                                        value={item.name}
                                                                                                        key={index}
                                                                                                        disabled={values.products
                                                                                                            .map((v) => v.name)
                                                                                                            .includes(item.name)}
                                                                                                    >
                                                                                                        {item.name}
                                                                                                    </MenuItem>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    <Field
                                                                                        name={`products.[${index}].name`}
                                                                                        render={({ form }) => {
                                                                                            const error = getIn(
                                                                                                form.errors,
                                                                                                `products.[${index}].name`
                                                                                            );
                                                                                            const touch = getIn(
                                                                                                form.touched,
                                                                                                `products.[${index}].name`
                                                                                            );
                                                                                            return touch && error ? (
                                                                                                <p className="error">{error}</p>
                                                                                            ) : null;
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    lg={5.5}
                                                                                    md={5.5}
                                                                                    sm={5.5}
                                                                                >
                                                                                    <FormLabel required>
                                                                                        Product link-{index + 1}
                                                                                    </FormLabel>

                                                                                    <NormalInput
                                                                                        name={`products.[${index}].link`}
                                                                                        type={"text"}
                                                                                        focused={false}
                                                                                        label={""}
                                                                                        sizeval="medium"
                                                                                        values={values.products[index].link}
                                                                                        isRequired={false}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                    />
                                                                                    <Field
                                                                                        class="error"
                                                                                        name={`products.[${index}].link`}
                                                                                        render={({ form }) => {
                                                                                            const error = getIn(
                                                                                                form.errors,
                                                                                                `products.[${index}].link`
                                                                                            );
                                                                                            const touch = getIn(
                                                                                                form.touched,
                                                                                                `products.[${index}].link`
                                                                                            );
                                                                                            return touch && error ? (
                                                                                                <p className="error">{error}</p>
                                                                                            ) : null;
                                                                                        }}
                                                                                    />
                                                                                </Grid>

                                                                                {productDropdown?.length > 1 ? (
                                                                                    <Grid
                                                                                        item
                                                                                        xs={12}
                                                                                        mt={3}
                                                                                        lg={0.5}
                                                                                        md={0.5}
                                                                                        sm={0.5}
                                                                                    >
                                                                                        {productDropdown?.length === 1 ||
                                                                                            (index === 0 &&
                                                                                                productDropdown?.length !==
                                                                                                values.products?.length) ? (
                                                                                            <img
                                                                                                src="/plus.png"
                                                                                                onClick={() => {
                                                                                                    if (!values.brandId) return;
                                                                                                    array.push({
                                                                                                        name: "",
                                                                                                        link: "",
                                                                                                    });
                                                                                                }}
                                                                                                alt="plus"
                                                                                                width={"25px"}
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src="/minus.png"
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    array.remove(index);
                                                                                                }}
                                                                                                alt="minus"
                                                                                                width={"25px"}
                                                                                            />
                                                                                        )}
                                                                                    </Grid>
                                                                                ) : (
                                                                                    <Grid
                                                                                        item
                                                                                        xs={12}
                                                                                        mt={3}
                                                                                        lg={0.5}
                                                                                        md={0.5}
                                                                                        sm={0.5}
                                                                                    >
                                                                                        {" "}
                                                                                    </Grid>
                                                                                )}
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            />

                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>Start Date</FormLabel>
                                                                <NormalInput
                                                                    name={"startDate"}
                                                                    type={"date"}
                                                                    focused={false}
                                                                    isDisabled={formData?.status === LIVE}

                                                                    isRequired={false}
                                                                    label={""}
                                                                    min={
                                                                        formData.mode === "edit" && formData?.status !== DRAFT
                                                                            ? values?.startDate || formatDate(new Date())
                                                                            : formatDate(new Date(), "dd/mm/yyyy")
                                                                    }
                                                                    //min={formatDate(new Date(), 'dd/mm/yyyy')}
                                                                    sizeval="medium"
                                                                    defaultValue={values?.startDate}
                                                                    values={values?.startDate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.startDate) &&
                                                                        errors.startDate
                                                                    }
                                                                    helperText={
                                                                        touched.startDate && errors.startDate
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>End Date</FormLabel>
                                                                <NormalInput
                                                                    name={"endDate"}
                                                                    type={"date"}
                                                                    focused={false}
                                                                    isRequired={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    isDisabled={!values.startDate}
                                                                    // isDisabled={new Date() > new Date(values?.endDate)}
                                                                    min={values?.startDate}
                                                                    values={values?.endDate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.endDate) && errors.endDate
                                                                    }
                                                                    helperText={touched.endDate && errors.endDate}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>Apply by date</FormLabel>
                                                                <NormalInput
                                                                    name={"applyBydate"}
                                                                    type={"date"}
                                                                    focused={false}
                                                                    isRequired={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    //isDisabled={!values?.startDate && !values.endDate && (values.shipmentDetails > 0 ? !values.productShipmentDate : !values?.conceptScriptDate)}
                                                                    isDisabled={!(values?.startDate && values.endDate)}
                                                                    min={values?.startDate}
                                                                    max={values?.endDate}
                                                                    //max={values.shipmentDetails.length > 0 ? values.productShipmentDate : values?.conceptScriptDate}
                                                                    values={values?.applyBydate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.applyBydate) && errors.applyBydate
                                                                    }
                                                                    helperText={touched.applyBydate && errors.applyBydate}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}></Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>Campaign Banner</FormLabel>
                                                                <Typography width={"200px"}>
                                                                    {/* <CampaignImage handleFile={(event) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "bannerImage",
                                                                            value: event
                                                                        }
                                                                    })

                                                                }}
                                                                    preview={true}
                                                                    text="Upload a sssfile"
                                                                /> */}

                                                                    <ImageUpload
                                                                        name="bannerImage"
                                                                        handleFile={async (event) => {
                                                                            const base64 = await fileToBase64(event);
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "bannerImage",
                                                                                    value: event,
                                                                                },
                                                                            });

                                                                            dispatch(updateCampaign({
                                                                                formData: {
                                                                                    ...formData, bannerImageBase64: JSON.stringify({
                                                                                        name: base64.name,
                                                                                        size: base64.size,
                                                                                        type: base64.type,
                                                                                        lastModified: base64.lastModified,
                                                                                        base64,
                                                                                    })
                                                                                }
                                                                            }))
                                                                        }}
                                                                        label=""
                                                                        fileUrl={values.bannerImage}
                                                                        previewImg={(image) => {
                                                                            setBannerPreview(image);
                                                                        }}
                                                                        fileObject={values.bannerImage}
                                                                    />
                                                                </Typography>
                                                                <small
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        fontWeight: "400",
                                                                        color: "#6C7692",
                                                                        display: "inline-block",
                                                                        lineHeight: "18px",
                                                                    }}
                                                                >
                                                                    Supported dimensions (pixels) are 1920 x 1080,1280 x 720 and
                                                                    1024 x 576.
                                                                </small>
                                                                {errors.bannerImage && touched.bannerImage && (
                                                                    <p className="error">
                                                                        {`${errors?.bannerImage}`}
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>Campaign Brief</FormLabel>
                                                                {/* <div style={{ overflow: 'auto', overflowY: 'auto', width: '100%' }}> */}
                                                                <Editor
                                                                    value={values.brief}
                                                                    onChange={(e) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: "brief",
                                                                                value: e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                    containerProps={{ style: { resize: 'vertical' } }}
                                                                />
                                                                {/* </div> */}
                                                                {errors.brief && touched.brief && (
                                                                    <p className="error m-0">
                                                                        {`${errors?.brief}`}
                                                                    </p>
                                                                )}
                                                                {/* <CustomTextArea
                                                                    name={"brief"}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.brief}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Message"
                                                                    multiline={true}
                                                                    error={Boolean(touched.brief) && errors.brief}
                                                                    helperText={touched.brief && errors.brief}
                                                                /> */}
                                                            </Grid>

                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <hr></hr>
                                                            </Grid>

                                                            <Grid item xs={12} lg={12} md={12} sm={12}>

                                                                <Typography variant="h4" component="h6">
                                                                    Concept Script Date
                                                                </Typography>
                                                                <Typography variant="subtitle1" gutterBottom>
                                                                    Select a date by which the creator needs to
                                                                    submit the following their content script for
                                                                    approval.
                                                                </Typography>
                                                            </Grid>

                                                            {[CAMPAIGN_TYPES.PAID, CAMPAIGN_TYPES.BARTER].includes(formData.type) && values.shipmentDetails.length > 0 && (
                                                                <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                    <FormLabel required>Product Shipment Date</FormLabel>
                                                                    <NormalInput
                                                                        key={values.shipmentDetails > 0}
                                                                        name={"productShipmentDate"}
                                                                        type={"date"}
                                                                        focused={false}
                                                                        label={""}
                                                                        sizeval="medium"
                                                                        isDisabled={!values.applyBydate}
                                                                        values={values?.productShipmentDate}
                                                                        placeholder={"mm/dd/yyyy"}
                                                                        min={values?.applyBydate}
                                                                        max={values?.endDate}
                                                                        onChange={(e) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "productShipmentDate",
                                                                                    value: e.target.value
                                                                                }
                                                                            })
                                                                            setFieldValue(
                                                                                "conceptScriptDate",
                                                                                formatDate(e.target.value)
                                                                            );
                                                                        }}
                                                                        error={
                                                                            Boolean(touched.productShipmentDate) &&
                                                                            errors.productShipmentDate
                                                                        }
                                                                        helperText={
                                                                            touched.productShipmentDate &&
                                                                            errors.productShipmentDate
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>
                                                                    Concept Script Date
                                                                </FormLabel>
                                                                <NormalInput
                                                                    name={"conceptScriptDate"}
                                                                    type={"date"}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values?.conceptScriptDate}
                                                                    placeholder={"mm/dd/yyyy"}
                                                                    isDisabled={!values?.applyBydate}
                                                                    // min={values?.productShipmentDate || values?.applyBydate || new Date()}
                                                                    min={values?.applyBydate}
                                                                    max={values?.endDate}
                                                                    //onChange={handleChange}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setFieldValue(
                                                                            "contentSubmissionDate",
                                                                            formatDate(e.target.value)
                                                                        );
                                                                    }}
                                                                    error={
                                                                        Boolean(touched.conceptScriptDate) &&
                                                                        errors.conceptScriptDate
                                                                    }
                                                                    helperText={
                                                                        touched.conceptScriptDate &&
                                                                        errors.conceptScriptDate
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>
                                                                    Content Submission Date
                                                                </FormLabel>
                                                                <NormalInput
                                                                    name={"contentSubmissionDate"}
                                                                    type={"date"}
                                                                    focused={false}
                                                                    label={""}
                                                                    isDisabled={values?.conceptScriptDate === ""}
                                                                    sizeval="medium"
                                                                    values={values?.contentSubmissionDate}
                                                                    placeholder={"mm/dd/yyyy"}
                                                                    //onChange={handleChange}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setFieldValue(
                                                                            "contentPublishDate",
                                                                            formatDate(e.target.value)
                                                                        );
                                                                    }}
                                                                    min={values?.conceptScriptDate}
                                                                    max={values?.endDate}
                                                                    error={
                                                                        Boolean(touched.contentSubmissionDate) &&
                                                                        errors.contentSubmissionDate
                                                                    }
                                                                    helperText={
                                                                        touched.contentSubmissionDate &&
                                                                        errors.contentSubmissionDate
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} lg={6} md={6} sm={6}>
                                                                <FormLabel required>
                                                                    Content Publishing Date
                                                                </FormLabel>
                                                                <NormalInput
                                                                    name={"contentPublishDate"}
                                                                    type={"date"}
                                                                    focused={false}
                                                                    label={""}
                                                                    isDisabled={
                                                                        values?.contentSubmissionDate === ""
                                                                    }
                                                                    sizeval="medium"
                                                                    values={values?.contentPublishDate}
                                                                    placeholder={"mm/dd/yyyy"}
                                                                    onChange={handleChange}
                                                                    max={values?.endDate}
                                                                    min={values?.contentSubmissionDate}
                                                                    error={
                                                                        Boolean(touched.contentPublishDate) &&
                                                                        errors.contentPublishDate
                                                                    }
                                                                    helperText={
                                                                        touched.contentPublishDate &&
                                                                        errors.contentPublishDate
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <hr></hr>
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <Typography variant="h4" component="h6">
                                                                    Campaign Budget
                                                                </Typography>
                                                            </Grid>

                                                            {formData.type === CAMPAIGN_TYPES.PAID && (
                                                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                    <FormLabel required>
                                                                        What is your overall budget for this
                                                                        campaign?
                                                                    </FormLabel>
                                                                    <NumberInput
                                                                        name={"budget"}
                                                                        type={"number"}
                                                                        isRequired={false}
                                                                        focused={false}
                                                                        label={""}
                                                                        sizeval="medium"
                                                                        values={values.budget}
                                                                        placeholder={"Ex. 50000"}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            Boolean(touched.budget) && errors.budget
                                                                        }
                                                                        helperText={touched.budget && errors.budget}
                                                                    />
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <FormLabel required>
                                                                    What is the total collaboration value per
                                                                    influencer for this campaign?
                                                                </FormLabel>
                                                                <NumberInput
                                                                    name={"budgetPerInfluencer"}
                                                                    type={"number"}
                                                                    isRequired={false}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.budgetPerInfluencer}
                                                                    placeholder={"Ex. 50000"}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.budgetPerInfluencer) &&
                                                                        errors.budgetPerInfluencer
                                                                    }
                                                                    helperText={
                                                                        touched.budgetPerInfluencer &&
                                                                        errors.budgetPerInfluencer
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <FormLabel required>No. of Creators</FormLabel>
                                                                <NumberInput
                                                                    name={"noOfCreators"}
                                                                    type={"number"}
                                                                    isRequired={false}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.noOfCreators}
                                                                    placeholder={"Ex. 50000"}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.noOfCreators) &&
                                                                        errors.noOfCreators
                                                                    }
                                                                    helperText={
                                                                        touched.noOfCreators && errors.noOfCreators
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <hr></hr>
                                                            </Grid>
                                                            {formData.type === CAMPAIGN_TYPES.PAID &&
                                                                <>
                                                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                        <Typography variant="h4" component="h6">
                                                                            Define Milestone Payments<CustomToolTip text={"You can choose what all milestones are to be included as part of your milestone payments by just entering values in the specific fields below. The field that are left blank won't become part of your campaign milestones."} />
                                                                        </Typography>
                                                                        <Typography variant="p" color={"#AAAAAA"} gutterBottom>
                                                                            Select the stages you want to keep millstones
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12} lg={12} md={12} sm={12}>

                                                                        <Accordion sx={{ boxShadow: "none" }}>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1-content"
                                                                                id="panel1-header"
                                                                                sx={{ flexDirection: 'row-reverse', color: "#AAAAAA" }}
                                                                            >
                                                                                Advance Payment
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <NumberInput
                                                                                    className="ttldiscount"
                                                                                    name={"advancePayment"}
                                                                                    isRequired={false}
                                                                                    startAdornment={true}
                                                                                    startAdornmentText="%"
                                                                                    type={"number"}
                                                                                    focused={false}
                                                                                    label={("")}
                                                                                    placeholder={""}
                                                                                    sizeval="medium"
                                                                                    values={values?.advancePayment}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                                            handleChange(e);
                                                                                        } else {
                                                                                            e.preventDefault();
                                                                                        }

                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    onKeyDown={(e) => {
                                                                                        phonePreventText.includes(e.key) &&
                                                                                            e.preventDefault()
                                                                                    }
                                                                                    }
                                                                                    error={
                                                                                        Boolean(touched.advancePayment) &&
                                                                                        errors.advancePayment
                                                                                    }
                                                                                    helperText={
                                                                                        touched.advancePayment &&
                                                                                        errors.advancePayment
                                                                                    }

                                                                                />
                                                                                <span style={{ position: "relative", top: 10, left: 10, color: "#AAAAAA" }} >(Usually brands pay 10% as standard practice)</span>

                                                                            </AccordionDetails>

                                                                        </Accordion>
                                                                        <Accordion sx={{ boxShadow: "none" }}>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1-content"
                                                                                id="panel1-header"
                                                                                sx={{ flexDirection: 'row-reverse', color: "#AAAAAA", mt: 2 }}
                                                                            >
                                                                                Script Approval
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <NumberInput
                                                                                    className="ttldiscount"
                                                                                    name={"scriptApproval"}
                                                                                    isRequired={false}
                                                                                    startAdornment={true}
                                                                                    startAdornmentText="%"
                                                                                    type={"number"}
                                                                                    focused={false}
                                                                                    label={("")}
                                                                                    placeholder={""}
                                                                                    sizeval="medium"
                                                                                    values={values?.scriptApproval}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                                            handleChange(e);
                                                                                        } else {
                                                                                            e.preventDefault();
                                                                                        }

                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    onKeyDown={(e) => {
                                                                                        phonePreventText.includes(e.key) &&
                                                                                            e.preventDefault()
                                                                                    }
                                                                                    }
                                                                                    error={
                                                                                        Boolean(touched.scriptApproval) &&
                                                                                        errors.scriptApproval
                                                                                    }
                                                                                    helperText={
                                                                                        touched.scriptApproval &&
                                                                                        errors.scriptApproval
                                                                                    }

                                                                                />

                                                                            </AccordionDetails>
                                                                        </Accordion>

                                                                        <Accordion sx={{ boxShadow: "none" }}>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1-content"
                                                                                id="panel1-header"
                                                                                sx={{ flexDirection: 'row-reverse', color: "#AAAAAA", mt: 2 }}
                                                                            >
                                                                                Content Approval
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <NumberInput
                                                                                    className="ttldiscount"
                                                                                    name={"contentApproval"}
                                                                                    isRequired={false}
                                                                                    startAdornment={true}
                                                                                    startAdornmentText="%"
                                                                                    type={"number"}
                                                                                    focused={false}
                                                                                    onKeyDown={(e) => {
                                                                                        phonePreventText.includes(e.key) &&
                                                                                            e.preventDefault()
                                                                                    }
                                                                                    }
                                                                                    label={("")}
                                                                                    placeholder={""}
                                                                                    sizeval="medium"
                                                                                    values={values?.contentApproval}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                                            handleChange(e);
                                                                                        } else {
                                                                                            e.preventDefault();
                                                                                        }

                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    error={
                                                                                        Boolean(touched.contentApproval) &&
                                                                                        errors.contentApproval
                                                                                    }
                                                                                    helperText={
                                                                                        touched.contentApproval &&
                                                                                        errors.contentApproval
                                                                                    }

                                                                                />

                                                                            </AccordionDetails>
                                                                        </Accordion>

                                                                        <Accordion sx={{ boxShadow: "none" }} >
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1-content"
                                                                                id="panel1-header"
                                                                                sx={{ flexDirection: 'row-reverse', color: "#AAAAAA", mt: 2 }}
                                                                            >
                                                                                Publish link approval
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <NumberInput
                                                                                    className="ttldiscount"
                                                                                    name={"linkApproval"}
                                                                                    isRequired={false}
                                                                                    startAdornment={true}
                                                                                    startAdornmentText="%"
                                                                                    type={"number"}
                                                                                    focused={false}
                                                                                    onKeyDown={(e) => {
                                                                                        phonePreventText.includes(e.key) &&
                                                                                            e.preventDefault()
                                                                                    }
                                                                                    }
                                                                                    label={("")}
                                                                                    placeholder={""}
                                                                                    sizeval="medium"
                                                                                    values={values?.linkApproval}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                                            handleChange(e);
                                                                                        } else {
                                                                                            e.preventDefault();
                                                                                        }

                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    error={
                                                                                        Boolean(touched.linkApproval) &&
                                                                                        errors.linkApproval
                                                                                    }
                                                                                    helperText={
                                                                                        touched.linkApproval &&
                                                                                        errors.linkApproval
                                                                                    }

                                                                                />

                                                                            </AccordionDetails>
                                                                        </Accordion>


                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12} sm={12} >
                                                                        {errors.totalMilestone && touched.totalMilestone && (
                                                                            <Box border={1} borderRadius={2} p={1} borderColor={"red"} color={"red"}>
                                                                                <Typography variant="body" gutterBottom >
                                                                                    Note : If you are opting for milestone based payments, please ensure that the total percentage combined in the fields that you have entered the value are amounting to 100%.
                                                                                </Typography>
                                                                            </Box>
                                                                        )}
                                                                    </Grid>
                                                                </>
                                                            }
                                                            <Grid
                                                                item

                                                                xs={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >

                                                                <button
                                                                    className="btnPrimary"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();

                                                                        let details = {
                                                                            name: values.name,
                                                                            brandId: values?.brandId,
                                                                            products: values?.products ? JSON.stringify(values?.products) : JSON.stringify([]),
                                                                            startDate: values?.startDate,
                                                                            endDate: values?.endDate,
                                                                            bannerImage: values?.bannerImage,
                                                                            brief: values?.brief,
                                                                            productShipmentDate: values?.productShipmentDate,
                                                                            conceptScriptDate: values?.conceptScriptDate,
                                                                            contentSubmissionDate: values?.contentSubmissionDate,
                                                                            contentPublishDate: values?.contentPublishDate,
                                                                            budget: values?.budget,
                                                                            budgetPerInfluencer: values?.budgetPerInfluencer,
                                                                            noOfCreators: values?.noOfCreators,
                                                                            applyBydate: values.applyBydate ? formatDate(values.applyBydate) : "",
                                                                            linkApproval: values?.linkApproval || 0,
                                                                            advancePayment: values?.advancePayment || 0,
                                                                            scriptApproval: values?.scriptApproval || 0,
                                                                            contentApproval: values?.contentApproval || 0,
                                                                        }
                                                                        let dataa = [values.name, values?.brandId, values?.startDate, values?.endDate, values?.bannerImage, values?.brief, values?.contentSubmissionDate, values?.contentPublishDate, (formData.type === "barter" ? "barter" : values?.budget), values?.budgetPerInfluencer, values?.noOfCreators, values.applyBydate];
                                                                        let detailsCompleted = (dataa.filter((v, i) => {
                                                                            return v === "";
                                                                        })?.length == 0 && values.products?.filter((v) => v.name !== '' && v.link !== '')?.length > 0) ? 1 : 0;
                                                                        setBar((prev) => ({ ...prev, detailsSection: detailsCompleted }))
                                                                        dispatch(updateCampaign({ detailsSection: detailsCompleted, formData: { ...formData, ...values } }));
                                                                        dispatch(addNewCampaign(details, navigate));
                                                                    }}
                                                                >
                                                                    Done
                                                                </button>

                                                            </Grid>

                                                        </Grid>

                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            {/* Define milestone payment */}
                                            {false && <Grid item mb={2}>
                                                <Accordion sx={{ bgcolor: "#f7f7f7" }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}

                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Typography variant="h4" component="h6">
                                                            Define Milestone payments
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} lg={3} md={3} sm={3} mt={1}>
                                                                <FormLabel>Application Approval</FormLabel>
                                                            </Grid>
                                                            <Grid item xs={12} lg={3} md={3} sm={12}>
                                                                <NumberInput
                                                                    className="ttldiscount"
                                                                    name={"advancePayment"}
                                                                    isRequired={false}
                                                                    startAdornment={true}
                                                                    startAdornmentText="%"
                                                                    type={"number"}
                                                                    focused={false}
                                                                    label={("")}
                                                                    placeholder={""}
                                                                    sizeval="medium"
                                                                    values={values?.advancePayment}
                                                                    onChange={(e) => {
                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                            handleChange(e);
                                                                        } else {
                                                                            e.preventDefault();
                                                                        }

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    onKeyDown={(e) => {
                                                                        phonePreventText.includes(e.key) &&
                                                                            e.preventDefault()
                                                                    }
                                                                    }
                                                                    error={
                                                                        Boolean(touched.advancePayment) &&
                                                                        errors.advancePayment
                                                                    }
                                                                    helperText={
                                                                        touched.advancePayment &&
                                                                        errors.advancePayment
                                                                    }

                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={3} md={3} sm={3} mt={1}>
                                                                <FormLabel>Script Approval</FormLabel>
                                                            </Grid>
                                                            <Grid item xs={12} lg={3} md={3} sm={12}>
                                                                <NumberInput
                                                                    className="ttldiscount"
                                                                    name={"scriptApproval"}
                                                                    isRequired={false}
                                                                    startAdornment={true}
                                                                    startAdornmentText="%"
                                                                    type={"number"}
                                                                    focused={false}
                                                                    label={("")}
                                                                    placeholder={""}
                                                                    sizeval="medium"
                                                                    values={values?.scriptApproval}
                                                                    onChange={(e) => {
                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                            handleChange(e);
                                                                        } else {
                                                                            e.preventDefault();
                                                                        }

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    onKeyDown={(e) => {
                                                                        phonePreventText.includes(e.key) &&
                                                                            e.preventDefault()
                                                                    }
                                                                    }
                                                                    error={
                                                                        Boolean(touched.scriptApproval) &&
                                                                        errors.scriptApproval
                                                                    }
                                                                    helperText={
                                                                        touched.scriptApproval &&
                                                                        errors.scriptApproval
                                                                    }

                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} lg={3} md={3} sm={3} mt={1}>
                                                                <FormLabel>Content Approval</FormLabel>
                                                            </Grid>
                                                            <Grid item xs={12} lg={3} md={3} sm={12}>
                                                                <NumberInput
                                                                    className="ttldiscount"
                                                                    name={"contentApproval"}
                                                                    isRequired={false}
                                                                    startAdornment={true}
                                                                    startAdornmentText="%"
                                                                    type={"number"}
                                                                    focused={false}
                                                                    onKeyDown={(e) => {
                                                                        phonePreventText.includes(e.key) &&
                                                                            e.preventDefault()
                                                                    }
                                                                    }
                                                                    label={("")}
                                                                    placeholder={""}
                                                                    sizeval="medium"
                                                                    values={values?.contentApproval}
                                                                    onChange={(e) => {
                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                            handleChange(e);
                                                                        } else {
                                                                            e.preventDefault();
                                                                        }

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.contentApproval) &&
                                                                        errors.contentApproval
                                                                    }
                                                                    helperText={
                                                                        touched.contentApproval &&
                                                                        errors.contentApproval
                                                                    }

                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={3} md={3} sm={3} mt={1}>
                                                                <FormLabel>Publish link approval</FormLabel>
                                                            </Grid>
                                                            <Grid item xs={12} lg={3} md={3} sm={12}>
                                                                <NumberInput
                                                                    className="ttldiscount"
                                                                    name={"linkApproval"}
                                                                    isRequired={false}
                                                                    startAdornment={true}
                                                                    startAdornmentText="%"
                                                                    type={"number"}
                                                                    focused={false}
                                                                    onKeyDown={(e) => {
                                                                        phonePreventText.includes(e.key) &&
                                                                            e.preventDefault()
                                                                    }
                                                                    }
                                                                    label={("")}
                                                                    placeholder={""}
                                                                    sizeval="medium"
                                                                    values={values?.linkApproval}
                                                                    onChange={(e) => {
                                                                        if (e.target.value <= 100) { //&& e.target.value !== "0"
                                                                            handleChange(e);
                                                                        } else {
                                                                            e.preventDefault();
                                                                        }

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.linkApproval) &&
                                                                        errors.linkApproval
                                                                    }
                                                                    helperText={
                                                                        touched.linkApproval &&
                                                                        errors.linkApproval
                                                                    }

                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12} >
                                                                <Box border={1} p={1} borderColor={"red"} color={"red"}>
                                                                    <Typography variant="body" gutterBottom >
                                                                        If you are opting for milestone based payments, please ensure that the total percentage combined in the fields that you have entered the value are amounting to 100%.
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                lg={3}
                                                                md={3}
                                                                sm={3}
                                                                mt={1}
                                                            ></Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <button
                                                                    className="btnPrimary"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let addtitonals = {
                                                                            name: values.name || "Draft",
                                                                            accountMentions: values?.accountMentions,
                                                                            campaignHashtag: values?.campaignHashtag,
                                                                            referenceContentUrl: values?.referenceContentUrl,
                                                                            referenceContent: values?.referenceContent,
                                                                            contactDetails: values?.contactDetails
                                                                        }

                                                                        let additionalCompleted = values.contactDetails ? 1 : 0
                                                                        setBar((prev) => ({ ...prev, addtionalSection: additionalCompleted }))
                                                                        dispatch(addNewCampaign(addtitonals, navigate));
                                                                        dispatch(updateCampaign({ addtionalSection: additionalCompleted, formData: { ...formData, ...values } }));
                                                                    }}
                                                                >
                                                                    Done
                                                                </button>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>}
                                            {/* End  milestone payment */}
                                            <Grid item mb={2}>
                                                <Accordion sx={{ bgcolor: "#f7f7f7" }}>
                                                    <CustomAccordionSummary
                                                        expandIcon={<Chip color={values.contactDetails !== "" ? "success" : "error"} variant="outlined" label={values.contactDetails !== "" ? "DONE" : "EDIT"} sx={{ borderRadius: 1 }} size="medium" />}

                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Typography variant="h4" component="h6">
                                                            Additional Requirements
                                                        </Typography>
                                                    </CustomAccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} lg={3} md={3} sm={3} mt={1}>
                                                                <FormLabel>Account Mentions</FormLabel>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} md={6} sm={6}>
                                                                <NormalInput
                                                                    name={"accountMentions"}
                                                                    type={"text"}
                                                                    isRequired={false}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.accountMentions}
                                                                    placeholder={""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.accountMentions) &&
                                                                        errors.accountMentions
                                                                    }
                                                                    helperText={
                                                                        touched.accountMentions &&
                                                                        errors.accountMentions
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                lg={3}
                                                                md={3}
                                                                sm={3}
                                                                mt={1}
                                                            ></Grid>

                                                            <Grid item xs={12} lg={3} md={3} sm={3} mt={1}>
                                                                <FormLabel>Campaign Hashtag</FormLabel>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} md={6} sm={6}>
                                                                <NormalInput
                                                                    name={"campaignHashtag"}
                                                                    type={"text"}
                                                                    isRequired={false}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.campaignHashtag}
                                                                    placeholder={""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.campaignHashtag) &&
                                                                        errors.campaignHashtag
                                                                    }
                                                                    helperText={
                                                                        touched.campaignHashtag &&
                                                                        errors.campaignHashtag
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} lg={12} md={12} sm={12}>


                                                                <div
                                                                    style={{
                                                                        border: "1px solid #aaa",
                                                                        borderRadius: "8px",
                                                                        padding: "5px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            position: "relative",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "10px",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            component="label"
                                                                            role={undefined}
                                                                            variant="contained"
                                                                            tabIndex={-1}
                                                                            style={{
                                                                                borderRadius: 5,
                                                                                background: "rgba(170, 170, 170, 0.5)",
                                                                                color: " rgba(0, 0, 0, 0.7)",
                                                                                fontSize: "14px",
                                                                                fontWeight: "500",
                                                                                lineHeight: "40px",
                                                                                boxShadow: "none",
                                                                                padding: "0px 10px",
                                                                            }}
                                                                        >
                                                                            Choose File
                                                                        </Button>
                                                                        <input
                                                                            style={{
                                                                                position: "absolute",
                                                                                bottom: 0,
                                                                                left: 0,
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                opacity: 0,
                                                                                cursor: "pointer",
                                                                            }}
                                                                            accept="video/*"
                                                                            type="file"
                                                                            onChange={(e) => {
                                                                                //e.target.value = null;
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: "videofileName",
                                                                                        value: e.target.files[0]?.name,
                                                                                    },
                                                                                });
                                                                                dispatch(
                                                                                    updateCampaign({
                                                                                        referenceContentUrl: "",
                                                                                    })
                                                                                );
                                                                                dispatch(
                                                                                    getPresignedurl(
                                                                                        e.target.files[0]?.name, //`${Math.floor(Date.now() / 1000)}`,
                                                                                        e.target.files[0].type.split(
                                                                                            "/"
                                                                                        )[1],
                                                                                        e.target.files[0],
                                                                                        (url) => {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: "referenceContentUrl",
                                                                                                    value: url,
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    )
                                                                                );
                                                                                setFieldValue(
                                                                                    "referenceContentUrl",
                                                                                    referenceContentUrl
                                                                                );
                                                                                e.target.value = null;
                                                                            }}
                                                                        />
                                                                        {values?.videofileName && (
                                                                            <p className="m-0">
                                                                                {values.videofileName}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <small
                                                                    style={{
                                                                        fontSize: "11px",
                                                                        fontWeight: "400",
                                                                        color: "#6C7692",
                                                                        display: "inline-block",
                                                                        lineHeight: "18px",
                                                                    }}
                                                                >
                                                                    Supported dimensions for video aspect ratios
                                                                    include 16:9 (widescreen)
                                                                </small>
                                                                <br></br>

                                                                <div
                                                                    onClick={handleVideo}
                                                                    key={values.referenceContentUrl.length}
                                                                >
                                                                    {/* poster={"https://i.ytimg.com/vi/rF5Yor08uGw/mqdefault.jpg"} */}
                                                                    {values.referenceContentUrl &&
                                                                        !globalLoader && (
                                                                            <video
                                                                                ref={videoRef}
                                                                                width="300"
                                                                                controls

                                                                            >
                                                                                <source
                                                                                    src={
                                                                                        referenceContentUrl ||
                                                                                        values.referenceContentUrl
                                                                                    }
                                                                                    type="video/mp4"
                                                                                    key={referenceContentUrl.length}
                                                                                />
                                                                                Your browser does not support the video
                                                                                tag.
                                                                            </video>
                                                                        )}
                                                                </div>
                                                                {(referenceContentUrl ||
                                                                    values.referenceContentUrl) && <Chip
                                                                        label="Remove Video"
                                                                        color="error"
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "videofileName",
                                                                                    value: "",
                                                                                },
                                                                            });
                                                                            dispatch(
                                                                                updateCampaign({
                                                                                    referenceContentUrl: "",
                                                                                })
                                                                            );
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "referenceContentUrl",
                                                                                    value: "",
                                                                                },
                                                                            });
                                                                        }}
                                                                    />}

                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <FormLabel> Reference Content</FormLabel>
                                                                <Editor
                                                                    value={values.referenceContent}
                                                                    onChange={(e) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: "referenceContent",
                                                                                value: e.target.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <Typography variant="h4" component="h6" mb={2}>
                                                                    Contact Details
                                                                </Typography>
                                                                <FormLabel required >
                                                                    Sharing your phone number will help us reach
                                                                    you faster, we won't spam you.
                                                                </FormLabel>
                                                                <NumberInput
                                                                    name={"contactDetails"}
                                                                    type={"number"}
                                                                    isRequired={false}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.contactDetails}
                                                                    placeholder={"Enter your phone number"}
                                                                    onChange={(e) => {
                                                                        if (e.target.value.length <= 10) {
                                                                            handleChange(e);
                                                                        } else {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(touched.contactDetails) &&
                                                                        errors.contactDetails
                                                                    }
                                                                    helperText={
                                                                        touched.contactDetails &&
                                                                        errors.contactDetails
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                lg={3}
                                                                md={3}
                                                                sm={3}
                                                                mt={1}
                                                            ></Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <button
                                                                    className="btnPrimary"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let addtitonals = {
                                                                            name: values.name || "Draft",
                                                                            accountMentions: values?.accountMentions,
                                                                            campaignHashtag: values?.campaignHashtag,
                                                                            referenceContentUrl: values?.referenceContentUrl,
                                                                            referenceContent: values?.referenceContent,
                                                                            contactDetails: values?.contactDetails
                                                                        }

                                                                        let additionalCompleted = values.contactDetails ? 1 : 0
                                                                        setBar((prev) => ({ ...prev, addtionalSection: additionalCompleted }))
                                                                        dispatch(addNewCampaign(addtitonals, navigate));
                                                                        dispatch(updateCampaign({ addtionalSection: additionalCompleted, formData: { ...formData, ...values } }));
                                                                    }}
                                                                >
                                                                    Done
                                                                </button>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>



                                        </Grid>

                                        <Grid
                                            mt={4}
                                            className="ps-xl-5 ps-lg-5 ps-md-5 ps-sm-3 ps-3"
                                            item
                                            xs={4}
                                            lg={4}
                                            md={4}
                                            sm={4}
                                        >
                                            {/* <Typography
                                                variant="h4"
                                                component="h4"
                                                style={{ textAlign: "center" }}
                                            >
                                                Campaign Data Preview
                                            </Typography>
                                            <Typography
                                                className="fs-6 mb-3"
                                                component="h6"
                                                py={1}
                                                px={4}
                                                style={{ textAlign: "center", overflow: "auto" }}
                                            >
                                                This section is a representation of how the influencers
                                                will see your campaign
                                            </Typography> */}
                                            {false && <Grid
                                                item
                                                p={3}
                                                mb={4}
                                                mr={2}
                                                bgcolor={"#f7f7f7"}
                                                xs={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                className=""
                                                style={{
                                                    border: "1px solid #AAAAAA",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    component="h4"
                                                    className="mb-3"
                                                >
                                                    Campaign Recommendation
                                                </Typography>
                                                <List className="listView">
                                                    <ListItem m={0} style={{ padding: "7px 0px" }}>
                                                        <ListItemText primary="No. of Influencers:" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText
                                                                primary="N/A"
                                                                style={{ textAlign: "right" }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem m={0} style={{ padding: "7px 0px" }}>
                                                        <ListItemText primary="Avg. Reach:" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText
                                                                primary="N/A"
                                                                style={{ textAlign: "right" }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem m={0} style={{ padding: "7px 0px" }}>
                                                        <ListItemText primary="Total:" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText
                                                                primary="N/A"
                                                                style={{ textAlign: "right" }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem m={0} style={{ padding: "7px 0px" }}>
                                                        <ListItemText primary="CPV (Cost per view):" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText
                                                                primary="N/A"
                                                                style={{ textAlign: "right" }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </List>
                                            </Grid>}
                                            <MobileComponent>
                                                <Grid
                                                    container
                                                    p={0}
                                                    m={0}
                                                    style={{
                                                        maxWidth: "350px",
                                                        position: "relative",
                                                        padding: "0",
                                                        margin: "0 auto",
                                                    }}
                                                >
                                                    <img
                                                        src="/mobile.png"
                                                        alt="mobile"
                                                        style={{
                                                            width: "100%",
                                                            padding: "0",
                                                            margin: "0 auto",
                                                        }}
                                                        height={"100%"}
                                                    />
                                                    <div className="previewElem">
                                                        <Grid
                                                            container
                                                            px={2}
                                                            py={2}
                                                            rowSpacing={2}
                                                            spacing={2}
                                                        >

                                                            {(bannerPreview !== null ||
                                                                values.bannerImage) && (
                                                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                        <img
                                                                            src={
                                                                                bannerPreview != null
                                                                                    ? bannerPreview
                                                                                    : (typeof values?.bannerImage === "object" && values?.bannerImage == {} ? URL.createObjectURL(values?.bannerImage) : values?.bannerImage)
                                                                            }
                                                                            style={{ width: "100%", height: "auto" }}
                                                                            alt="bannerImage"
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            <Grid item xs={12} lg={6} md={6} sm={6}>
                                                                <FormLabel>Campaign Name</FormLabel>
                                                                <NormalInput
                                                                    name={"name"}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={values.name || formData?.name}
                                                                    readOnly={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} md={6} sm={6}>
                                                                <FormLabel>Brand Name</FormLabel>
                                                                <NormalInput
                                                                    name={"brandName"}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={
                                                                        values.brandName ||
                                                                        formData?.brand?.brandName
                                                                    }
                                                                    readOnly={true}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                                <FormLabel>Campaign Brief</FormLabel>
                                                                <CustomTextArea
                                                                    name={"handle"}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    label={""}
                                                                    sizeval="medium"
                                                                    values={
                                                                        removeHtmltags(values.brief) ||
                                                                        removeHtmltags(formData?.brief)
                                                                    }
                                                                    placeholder=""
                                                                    multiline={true}
                                                                    style={{ m: 0, p: 0 }}
                                                                    isDisabled={false}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </MobileComponent>

                                            {CAMPAIGN_TYPES.AFFILIATE === formData.type && false && (
                                                <Grid
                                                    ml={2}
                                                    item
                                                    p={3}
                                                    bgcolor={"#EEEEEE"}
                                                    xs={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    className="with-border"
                                                >
                                                    <Typography
                                                        mb={1}
                                                        sx={{ fontWeight: 600, fontSize: "20px" }}
                                                    >
                                                        KPI Industry Estimates
                                                    </Typography>
                                                    <List>
                                                        <ListItem disableGutters style={{ padding: 0 }}>
                                                            <ListItemText primary="Sales" />
                                                            <ListItemSecondaryAction>
                                                                <ListItemText
                                                                    primary="1,00,000"
                                                                    style={{ textAlign: "right" }}
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem disableGutters style={{ padding: 0 }}>
                                                            <ListItemText primary="Website landing page view" />
                                                            <ListItemSecondaryAction>
                                                                <ListItemText
                                                                    primary="1,000"
                                                                    style={{ textAlign: "right" }}
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem disableGutters style={{ padding: 0 }}>
                                                            <ListItemText primary="Clicks" />
                                                            <ListItemSecondaryAction>
                                                                <ListItemText
                                                                    primary="100"
                                                                    style={{ textAlign: "right" }}
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem disableGutters style={{ padding: 0 }}>
                                                            <ListItemText primary="Registration" />
                                                            <ListItemSecondaryAction>
                                                                <ListItemText
                                                                    primary="300"
                                                                    style={{ textAlign: "right" }}
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem disableGutters style={{ padding: 0 }}>
                                                            <ListItemText primary="App Install" />
                                                            <ListItemSecondaryAction>
                                                                <ListItemText
                                                                    primary="5000"
                                                                    style={{ textAlign: "right" }}
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem disableGutters style={{ padding: 0 }}>
                                                            <ListItemText primary="Leads" />
                                                            <ListItemSecondaryAction>
                                                                <ListItemText
                                                                    primary="2500"
                                                                    style={{ textAlign: "right" }}
                                                                />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <hr />
                                        <Grid
                                            item
                                            p={3}
                                            xs={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <Stack direction={"row"} spacing={1}>
                                                {formData?.mode == "add" && (
                                                    <button
                                                        className="btnSecondary"
                                                        onClick={() => {
                                                            dispatch(updateCampaign({ step: 2 }));
                                                            navigate("/campaign/type");
                                                        }}
                                                    >
                                                        Back
                                                    </button>
                                                )}

                                                <button
                                                    className={(parseInt(deliverablesSection) + parseInt(detailsSection) + parseInt(addtionalSection) !== 3) ? "btnDisabled" : "btnPrimary"}
                                                    type="submit"// && 
                                                    // disabled={formData?.mode === "add" ? !(isValid && dirty) : (parseInt(deliverablesSection) + parseInt(detailsSection) + parseInt(addtionalSection) !== 3)}
                                                    disabled={(parseInt(deliverablesSection) + parseInt(detailsSection) + parseInt(addtionalSection) !== 3)}
                                                >
                                                    Set Campaign Info
                                                </button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </Stack >
        </>
    );
};
export default Info;
