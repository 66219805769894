import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { api } from "../../utils/api";
import { encodedURI, getCompanyid, removeEmptyValues, setCompanyFlowStatus } from "../../utils/common";
import { endpoints } from "../../utils/endpoints";
import { ROUTES } from "../../utils/routes";
import { updateNotification } from "./notificationSlice";

export const getAllCompany = () => {
  return (dispatch, getState) => {

    api.getApiCall(
      endpoints.brands,
      "",
      (respData) => {
        dispatch(updateNotification({ brandsDropdown: respData.data.result.data, }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllSubscriptions = () => {
  return (dispatch, getState) => {
    const { limit, page, filters } = getState().subscriptionSlice;
    const filterData = encodedURI(removeEmptyValues(filters));
    api.getApiCall(
      endpoints.getAllSubscription,
      `?limit=${limit}&offset=${page * limit}&filter=${filterData}`,
      (respData) => {
        dispatch(updateNotification({ listing: respData.data.result, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const subscriptionDetails = (id, setValue) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.subscriptionDetails + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateNotification({ details: respData.data.result }));
        setValue(respData.data.result)
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllCompanyforDropdown = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getAllCompanies,
      ``,
      (respData) => {
        dispatch(updateNotification({ companyListing: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
export const getActiveDefaultPlan = () => {
  return (dispatch, getState) => {
    const { activeFilters } = getState().subscriptionSlice;
    const filterData = encodedURI(removeEmptyValues(activeFilters));
    api.getApiCall(
      endpoints.getAllSubscription,
      `?filter=${filterData}`,
      (respData) => {
        dispatch(updateNotification({ defaultActivePlan: respData.data.result.length }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllActivePlans = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getAllActivePlans,
      ``,
      (respData) => {
        dispatch(updateNotification({ activePlans: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getCompanyDetails = (id, navigate) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getCompanyById + `/${id}`,
      ``,
      (respData) => {
        respData.data.result.comingFrom = "profile";
        navigate("/company/profile/" + id, { state: respData.data.result })
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};



export const createSubscription = (values, navigate) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.addSubscription,
      values,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "SUCCESS") {
          dispatch(updateCommonData({
            message: `Your plan has been ${values.id ? "updated" : 'created'} successfully `,
            title: "Success",
            img: "/success2.png",
            status: true
          }));
          navigate(ROUTES.SUBSCRIPTION_MGT)
        } else {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }


}

export const updateNotificationQandA = (values, hideModal) => {
  return (dispatch, getState) => {
    //dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateNotificationQandA + `/${getCompanyid()}`,
      values,
      (respData) => {
        // dispatch(updateGlobalLoader(false));
        hideModal(false);
        setCompanyFlowStatus(0);
      },
      (error) => {
        //dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );


  }
}




export const updateNotificationStatus = (id, status) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.subscriptionStatusUpdate + `/${id}`,
      { status: status },
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status !== "SUCCESS") {
          dispatch(updateCommonData({
            message: "something went to wrong",
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
          return;
        }
        dispatch(getAllSubscriptions());
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );
  };
};



export const updateUserStatus = (postData) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateUserStatus,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        dispatch(updateCommonData({
          message: "Status successfully updated",
          title: "Success",
          img: "/success.png",
          status: true
        }));
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data?.msg || "Api error",
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
        //Alert(2, data?.msg);
      }
    );
  };
};


