import * as Yup from "yup";
import { MAX_10DIGIT, NUMBER_VALIDATION, PASSWORD_REGEX, SUBSCRIPTION_ENUM, WEBSITE_REGEX } from "../utils/constant";
const Company = () => {
  return Yup.object().shape({
    companyName: Yup.string().trim().required("Company Name is required"),
    contactPersonName: Yup.string().trim().required("Contact person is required"),
    phoneNumber: Yup.string().required("Phone is required").matches(MAX_10DIGIT, "Phone number must be exactly 10 digits"),
    email: Yup.string().trim().email().required("Email is required"),
    niche: Yup.array()
      .of(Yup.mixed().required("Niche is required"))
      .min(1, "Niche is required"),
    geoPreferences: Yup.array()
      .of(Yup.mixed().required("Niche is required"))
      .min(1, "Niche is required"),
    logo: Yup.string().trim().required("Logo is required"),
    primaryColor: Yup.string().trim().required("Primary color is required"),
    font: Yup.string().trim().required("Font Preference is required"),
    instagramInfo: Yup.array().of(
      Yup.object().shape({
        userName: Yup.mixed().required("Username is required"),
        url: Yup.string()
          .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
      }),
    ),
    // linkedinInfo: Yup.array().of(
    //   Yup.object().shape({
    //     userName: Yup.mixed().required("Username is required"),
    //     url: Yup.string()
    //       .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
    //   }),
    // ),
    youtubeInfo: Yup.array().of(
      Yup.object().shape({
        userName: Yup.mixed().required("Username is required"),
        url: Yup.string()
          .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
      }),
    ),
    // facebookInfo: Yup.array().of(
    //   Yup.object().shape({
    //     userName: Yup.mixed().required("Username is required"),
    //     url: Yup.string()
    //       .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
    //   }),
    // ),
    id: Yup.mixed().nullable(),
    password: Yup.mixed().when("id", {
      is: (id) => id === undefined || id == null || id === '',
      then: () => Yup.string().required("Password is required").min(8, "Password should be at least 8 characters long").max(20, "Password should be at most 8 characters long").matches(PASSWORD_REGEX, "Your password must include lowercase letters, uppercase letters, numbers, and special symbols"),
      otherwise: () => Yup.string().nullable(true),
    }),
    confirmPassword: Yup.mixed().when("id", {
      is: (id) => id === undefined || id == null || id === '',
      then: () => Yup.string().required("Password is required").min(8, "Password should be at least 8 characters long").max(20, "Password should be at most 50 characters long").oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: () => Yup.string().nullable(true),
    }),
  });
};

const CampaignInfo = () => {
  return Yup.object().shape({
    platform: Yup.string().required("Platform is required"),
    name: Yup.string().required("Name is required").min(2, "Name must be greater than 2 characters.").max(60, "Name must be less than 60 characters."),
    brandId: Yup.string().required("Brand name is required"),
    products: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        link: Yup.string().required("Link is required").matches(WEBSITE_REGEX, "Please provide a valid link"),
      }),
    ),
    selectedDeliverables: Yup.array().test(
      "is-less-than-total",
      "At least select one value from dropdown with quantity is required",
      (value, testContext) => {
        return value?.length > 0 && value?.filter((v) => v?.post && v?.qty).length
      },
    ),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    applyBydate: Yup.string().required("Apply date is required"),
    shipmentDetails: Yup.array().nullable(),
    productShipmentDate: Yup.mixed().when("shipmentDetails", {
      is: (shipmentDetails) => shipmentDetails.length > 0,
      then: () => Yup.string().required("Product shipment date is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    bannerImage: Yup.string().required("Banner Image is required"),
    brief: Yup.string().trim().required("Brief is required").min(2, "Brief must be greater than 2 characters."),
    type: Yup.string().required(),
    conceptScriptDate: Yup.string().required("Concept script date is required"),
    contentSubmissionDate: Yup.string().required("Concept submission date is required"),
    contentPublishDate: Yup.string().required("Concept publish date is required"),
    budget: Yup.mixed().when("type", {
      is: (step) => step === "paid",
      then: () => Yup.string().matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("Budget is required").max(10, "Maximum 10 digit allowed only"),
      otherwise: () => Yup.string().nullable(true),
    }),

    budgetPerInfluencer: Yup.string().matches(NUMBER_VALIDATION, "Only numeric value is allowed")
      .required("Budget per influencer is required")
      .max(10, "Maximum 10 digit allowed only"),
    noOfCreators: Yup.string().matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("No of creators is required").max(10, "Maximum 10 digit allowed only"),
    contactDetails: Yup.string().min(10, "Contact details must be at least 10 characters").max(10, "Contact details must be at most 10 characters").matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("Contact Details is required"),
    // advancePayment: Yup.string().nullable(true),
    // scriptApproval: Yup.string().nullable(true),
    // contentApproval: Yup.string().nullable(true),
    // linkApproval: Yup.string().nullable(true),
    totalMilestone: Yup.string().test('sumCheck', 'The sum of all milestones input values must be 100%', function (value, testContext) {
      const total = Number(testContext.parent.advancePayment || 0) + Number(testContext.parent.scriptApproval || 0) + Number(testContext.parent.contentApproval || 0) + Number(testContext.parent.linkApproval || 0);
      console.log("total", total);
      return total > 0 ? total == 100 : true;
    })
  });
};
const ProfilerScreener = () => {
  return Yup.object().shape({
    campaignApplyingPreferences: Yup.array(),
    influncerType: Yup.array()
      .when('campaignApplyingPreferences', {
        is: (val) => val && val.length === 0,
        then: () => Yup.array()
          .of(Yup.mixed().required("Influncer Type is required"))
          .min(1, "Influncer Type is required"),
        otherwise: () => Yup.array(),
      }),
    creatorLocation: Yup.array()
      .when('campaignApplyingPreferences', {
        is: (val) => val && val.length === 0,
        then: () => Yup.array()
          .of(Yup.mixed().required("Location is required"))
          .min(1, "Location is required"),
        otherwise: () => Yup.array(),
      }),
    selectedCategories: Yup.array()
      .when('campaignApplyingPreferences', {
        is: (val) => val && val.length === 0,
        then: () => Yup.array()
          .of(Yup.mixed().required("Please select at least one category"))
          .min(1, "Please select at least one category"),
        otherwise: () => Yup.array(),
      }),
  });
};

const Step3 = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required").min(2, "Name must be greater than 2 characters.").max(60, "Name must be less than 60 characters."),
    brandId: Yup.string().required("Brand name is required"),
    bannerImage: Yup.string().required("Brand image is required"),
    startDate: Yup.string().required("Start date is required"),
    // productName: Yup.string().required("Product name is required"),
    // productLink: Yup.string()
    //   .required("Please provide a link")
    //   .matches(WEBSITE_REGEX, "Please provide a valid link"),
    type: Yup.string().required(),
    kpi: Yup.array().test(
      "is-less-than-total",
      "Kpi is required",
      (value, testContext) => {
        return testContext.parent.type === "affiliate" && value.length === 0 ? false : true
      },
    ),
    deliverablesForInstagram: Yup.array().test(
      "is-less-than-total",
      "At least one plateform is required with quantity and post",
      (value, testContext) => {
        const instagramHasDeliverables = testContext.parent.deliverablesForInstagram.filter((v) => v.qty && v.post).length > 0;
        const youtubeHasDeliverables = testContext.parent.deliverablesForYoutube.filter((v) => v.qty && v.post).length > 0;
        const facebookHasDeliverables = testContext.parent.deliverablesForFacebook.filter((v) => v.qty && v.post).length > 0;
        return instagramHasDeliverables || youtubeHasDeliverables || facebookHasDeliverables;
      },
    ),
    endDate: Yup.string().required("End date is required"),
    brandLogo: Yup.string().trim().required("Brand logo is required"),
    brandThumbnail: Yup.string().trim().required("Brand thumbnail is required"),
    brief: Yup.string().trim().required("Brief is required").min(2, "Brief must be greater than 2 characters."),
    products: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        link: Yup.string().required("Link is required").matches(WEBSITE_REGEX, "Please provide a valid link"),
      }),
    ),
  });
};
const Step4 = () => {
  return Yup.object().shape({
    //budget: Yup.string().trim().matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("Budget is required").max(10, "Maximum 10 digit allowed only"),
    noOfCreators: Yup.string().trim().matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("No of creators is required").max(10, "Maximum 10 digit allowed only"),
    creatorLocation: Yup.array()
      .of(Yup.mixed().required("Location is required"))
      .min(1, "Location is required"),
    type: Yup.string(),
    budget: Yup.mixed().when("type", {
      is: (step) => step === "paid",
      then: () => Yup.string().trim().matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("Budget is required").max(10, "Maximum 10 digit allowed only"),
      otherwise: () => Yup.string().nullable(true),
    }),

    budgetbudgetPerInfluencer: Yup.string().matches(NUMBER_VALIDATION, "Only numeric value is allowed").required(
      "Budget per influencer is required"
    ).max(10, "Maximum 10 digit allowed only"),
    // budgetbudgetPerInfluencer: Yup.string().test(
    //   "is-less-than-total",
    //   "Budget per influencer is required",
    //   (value, testContext) => {
    //     console.log("vaaaa", value);
    //     return testContext.parent.type === "barter" && value == undefined ? false : true
    //   },
    // ).max(10, "Maximum 10 digit allowed only"),
    influncerType: Yup.array()
      .of(Yup.mixed().required("Influncer Type is required"))
      .min(1, "Influncer Type is required"),
    location: Yup.array()
      .of(Yup.mixed().required("Location is required"))
      .min(1, "Location is required"),
    selectedCategories: Yup.array()
      .of(Yup.mixed().required("Please select at least one category"))
      .min(1, "Please select at least one category"),
    contactDetails: Yup.string().trim().min(10, "Contact details must be at least 10 characters").max(10, "Contact details must be at most 10 characters").matches(NUMBER_VALIDATION, "Only numeric value is allowed").required("Contact Details is required"),
  });
};
const Step5 = () => {
  return Yup.object().shape({
    conceptScriptDate: Yup.string().required("Concept script date is required"),
    contentSubmissionDate: Yup.string().required("Concept submission date is required"),
    contentPublishDate: Yup.string().required("Concept publish date is required"),
  });
};
const InviteInfluencer = () => {
  return Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
  });
};

const InviteMoreInfluencer = () => {
  return Yup.object().shape({
    influencer_ids: Yup.array().of(
      Yup.object().shape({
        id: Yup.mixed().required("Please provide a influencer")
      })
    ).min(1, "Please provide a influencer")
  });
};

const BidNegotiate = () => {
  return Yup.object().shape({
    amount: Yup.number().required("Amount is required")//.max(10, "Maximum 10 digit allowed only"),
  });
};
//matches(NUMBER_VALIDATION, "Only numeric value is allowed")
const BrandsForCompany = () => {
  return Yup.object().shape({
    brandImage: Yup.mixed().required("Brand image is required"),
    brandName: Yup.string().trim().required("Brand name is required").min(2, "Brand name must be greater than 2 characters.").max(60, "Brand name must be less than 60 characters."),
    product: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Product name is required")
      }),
    ),
    // product: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.mixed().required("Product name is required")
    //   }),
    // ),
  });
};

const ScriptDescription = Yup.object().shape({
  scripts: Yup.array().of(
    Yup.object().shape({
      status: Yup.string(), // Assuming status is a string type, adjust if necessary
      description: Yup.string().when('status', {
        is: 'REJECTED',
        then: Yup.string().required('Description is required for rejected status'),
        otherwise: Yup.string().nullable(),
      }),
    })
  )
});
const CompanySignup = () => {
  return Yup.object().shape({
    companyName: Yup.string().trim().required("Company Name is required").min(2, "Company name at least 2 character").max(50, "Brand name must be at most 50 characters long"),
    contactPersonName: Yup.string().trim().required("Contact person is required").min(2, "Contact person at least 2 character").max(50, "Contact person must be at most 50 characters long"),
    phoneNumber: Yup.string().trim().required("Phone is required").min(10, "Phone number must be at least 10 characters").max(15).matches(NUMBER_VALIDATION, "Only numeric value is allowed"),
    email: Yup.string().trim().email().required("Email is required").min(2, "Email at least 2 character"),
    password: Yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .matches(PASSWORD_REGEX, "Your password must include lowercase letters, uppercase letters, numbers, and special symbols")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match")

    // niche: Yup.array()
    //   .of(Yup.mixed().required("Niche is required"))
    //   .min(1, "Niche is required"),
    // step: Yup.mixed(),
    // geoPreferences: Yup.mixed().when("step", {
    //   is: (step) => step === 1,
    //   then: () => Yup.array()
    //     .of(Yup.string().required("Geo Preferences is required"))
    //     .min(1, "Geo Preferences is required"),
    //   otherwise: () => Yup.array().nullable(true),
    // }),
    // description: Yup.string().trim().required("Description is required").min(2, "Description at least 2 character"),
    // logo: Yup.mixed().when("step", {
    //   is: (step) => step === 2,
    //   then: () => Yup.string().trim().required("Logo is required"),
    //   otherwise: () => Yup.string().nullable(true),
    // }),
    // primaryColor: Yup.mixed().when("step", {
    //   is: (step) => step === 2,
    //   then: () => Yup.string().trim().required("Primary color is required").min(2, "Primary color at least 2 character").max(50, "Primary color must be at most 50 characters long"),
    //   otherwise: () => Yup.string().nullable(true),
    // }),
    // font: Yup.mixed().when("step", {
    //   is: (step) => step === 2,
    //   then: () => Yup.string().trim().required("Font Preference is required"),
    //   otherwise: () => Yup.string().nullable(true),
    // }),
    // instagramInfo: Yup.mixed().when("step", {
    //   is: (step) => step === 3,
    //   then: () => Yup.array().of(
    //     Yup.object().shape({
    //       userName: Yup.mixed().required("Username is required"),
    //       url: Yup.string()
    //         .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
    //     }),
    //   ),
    //   otherwise: () => Yup.array().nullable(true),
    // }),
    // facebookInfo: Yup.mixed().when("step", {
    //   is: (step) => step === 3,
    //   then: () => Yup.array().of(
    //     Yup.object().shape({
    //       userName: Yup.mixed().required("Username is required"),
    //       url: Yup.string()
    //         .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
    //     }),
    //   ),
    //   otherwise: () => Yup.array().nullable(true),
    // }),
    // linkedinInfo: Yup.mixed().when("step", {
    //   is: (step) => step === 3,
    //   then: () => Yup.array().of(
    //     Yup.object().shape({
    //       userName: Yup.mixed().required("Username is required"),
    //       url: Yup.string()
    //         .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
    //     }),
    //   ),
    //   otherwise: () => Yup.array().nullable(true),
    // }),
    // youtubeInfo: Yup.mixed().when("step", {
    //   is: (step) => step === 3,
    //   then: () => Yup.array().of(
    //     Yup.object().shape({
    //       userName: Yup.mixed().required("Username is required"),
    //       url: Yup.string()
    //         .trim().required("URL  is required").matches(WEBSITE_REGEX, "Please provide a valid URL"),
    //     }),
    //   ),
    //   otherwise: () => Yup.array().nullable(true),
    // }),

  });
};


const ForgotPassword = () => {
  return Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required")
  });
};

const otpVerfication = () => {
  return Yup.object().shape({
    otp: Yup.string().trim().required("Please enter OTP").min(6, "OTP should be 6 digits").max(6, "OTP should be 6 digits")
  });
};

const ResetPassword = () => {
  return Yup.object().shape({
    password: Yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .matches(PASSWORD_REGEX, "Your password must include lowercase letters, uppercase letters, numbers, and special symbols")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
};
const Subscriptions = () => {
  return Yup.object().shape({
    name: Yup.string().trim().required("Plan Name is required").matches(/^[a-zA-Z0-9 ]+$/, "Special character is not allowed").min(2, "Plan name must be atleast 2 character"),
    type: Yup.string().trim().required("Plan Type is required"),
    phoneNumber: Yup.string().nullable(true).max(10, "Maximum 10 digit allowed"),
    email: Yup.string().trim().nullable(true).max(10, "Maximum 10 digit allowed"),
    ordering: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.STANDARD,
      then: () => Yup.string().required("Indexing is required").max(2, "Maximum two digit is allowed"),
      otherwise: () => Yup.string().nullable(true),
    }),
    planCost: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.CUSTOM,
      then: () => Yup.mixed().required("Plan cost is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    companyId: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.CUSTOM,
      then: () => Yup.mixed().required("Company name is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    startDate: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.CUSTOM,
      then: () => Yup.mixed().required("Start date is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    endDate: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.CUSTOM,
      then: () => Yup.mixed().required("End date is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    monthlyRate: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.STANDARD,
      then: () => Yup.mixed().required("Monthly rate is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    quarterlyRate: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.STANDARD,
      then: () => Yup.mixed().required("Quarterly rate is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    yearlyRate: Yup.mixed().when("type", {
      is: (type) => type === SUBSCRIPTION_ENUM.STANDARD,
      then: () => Yup.mixed().required("Yearly rate is required"),
      otherwise: () => Yup.string().nullable(true),
    })
  });
};
const CompanyUser = () => {
  return Yup.object().shape({
    contactPersonName: Yup.string().trim().required("Name is required").min(2, "Name at least 2 character").max(50, "Name must be at most 50 characters long"),
    phoneNumber: Yup.string().trim().required("Phone is required").min(10, "Phone number must be at least 10 characters").max(15).matches(NUMBER_VALIDATION, "Only numeric value is allowed"),
    email: Yup.string().trim().email().required("Email is required").min(2, "Email at least 2 character"),
    password: Yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .matches(PASSWORD_REGEX, "Your password must include lowercase letters, uppercase letters, numbers, and special symbols")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });
};

const TalkToExpert = () => {
  return Yup.object().shape({
    fullName: Yup.string().trim().required("Full name is required").min(2, "Full name at least 2 character").max(50, "Full name must be at most 50 characters long"),
    phoneNo: Yup.string().trim().required("Phone is required").min(10, "Phone number must be at least 10 characters").max(10),
    workEmail: Yup.string().trim().email().required("Work email is required").min(2, "Work email at least 2 character"),
  });
};

const addCompanyUser = () => {
  return Yup.object().shape({
    contactPersonName: Yup.string().trim().required("Name is required").min(2, "Name at least 2 character").max(50, "Name must be at most 50 characters long"),
    phoneNumber: Yup.string().trim().required("Phone is required").min(10, "Phone number must be at least 10 characters").max(15).matches(NUMBER_VALIDATION, "Only numeric value is allowed"),
    email: Yup.string().trim().email().required("Email is required").min(2, "Email at least 2 character"),
    id: Yup.string().trim().nullable(true),
    password: Yup.mixed().when("id", {
      is: (id) => !id,
      then: () => Yup
        .string()
        .min(8, "Password should be of minimum 8 characters length")
        .matches(PASSWORD_REGEX, "Your password must include lowercase letters, uppercase letters, numbers, and special symbols")
        .required("Password is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    confirmPassword: Yup.mixed().when("password", {
      is: (password) => password,
      then: () => Yup.string()
        .required("Confirm Password is Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: () => Yup.string().nullable(true),
    }),
    // password: Yup
    //   .string()
    //   .min(8, "Password should be of minimum 8 characters length")
    //   .matches(PASSWORD_REGEX, "Your password must include lowercase letters, uppercase letters, numbers, and special symbols")
    //   .required("Password is required"),
    // confirmPassword: Yup.string()
    //   .required("Confirm Password is Required")
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  });
};
const Schema = {
  Company,
  CampaignInfo,
  ProfilerScreener,
  Step3,
  Step4,
  Step5,
  InviteMoreInfluencer,
  InviteInfluencer,
  BrandsForCompany,
  BidNegotiate,
  ScriptDescription,
  CompanyUser,
  CompanySignup,
  ForgotPassword,
  ResetPassword,
  otpVerfication,
  Subscriptions,
  addCompanyUser,
  TalkToExpert
}
export default Schema;