import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsButtons from '../../../components/buttons';
import CommonModel from '../../../components/dialog/Common';
import { CustomModal } from "../../../components/modal";
import { dateForamt, getUserType } from '../../../utils/common';
import { ADMIN, CAMPAIGN_TYPES, COMPANY, LIVE, UNPUBLISHED } from '../../../utils/constant';
import { getCampaignById, updateCampaignStatus } from '../action';
import { updateCampaign } from "../campaignSlice";


// company Details component
const CampaignDetails = () => {
    const [openNew, setOpennew] = useState(false);
    const [unpublishOpen, setOpenUnpublish] = useState(false);
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { formData, msgDisplay } = useSelector((state) => state.campaignSlice);
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState({
        heading: "",
        submitButton: "",
        isError: false,
    });

    useEffect(() => {
        dispatch(getCampaignById(id));

    }, [])

    const handleSubmit = (data) => {
        const payload = {
            campaignId: id,
            description: data.description,
            status: data.status
        }

        dispatch(updateCampaignStatus(payload))
        navigate("/campaign-management")
    }



    return <>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={3} mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" component="h1">View Campaign Details</Typography>
                <Stack
                    className="backText"
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        if (getUserType() !== COMPANY) {
                            navigate("/campaign-management");
                        } else {
                            navigate("/campaign/partial/details/" + id);
                        }
                    }}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"}>
                        Back
                    </Typography>
                </Stack>

            </Stack>
            {/* {getUserType() === COMPANY &&} */}

            {formData.status === "PENDING" && getUserType() !== COMPANY &&
                <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }}>
                    <button
                        className='btnPrimary'
                        onClick={() => {
                            setOpen(true);
                            setModal({
                                heading: "Approve Campaign",
                                submitButton: "Approve",
                            });
                        }}
                    >
                        Approve
                    </button>
                    <button
                        className='btnDanger'
                        onClick={() => {
                            setOpen(true);
                            setModal({
                                heading: "Reject Campaign",
                                submitButton: "Reject",
                                creating: false,
                            });
                        }}
                    >
                        Reject
                    </button>
                </Stack>
            }
            {formData.status === LIVE && getUserType() !== COMPANY && <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }}>
                <button
                    className='btnPrimary'
                    onClick={() => setOpenUnpublish(true)}
                    style={{ height: 41, marginTop: 3 }}
                >
                    UNPUBLISH
                </button>
                {formData.status === LIVE && <DetailsButtons />}
            </Stack>}

        </Stack>
        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Grid container>
                <CustomModal
                    open={open}
                    onClose={() => setOpen(!open)}
                    modal={modal}
                    approveFn={handleSubmit}
                />
                <div className='w-100'>
                    <div className='row flex-xxl-row flex-xl-row flex-lg-column-reverse flex-md-column-reverse flex-sm-column-reverse flex-column-reverse'>
                        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='d-flex flex-wrap gap-4 mb-4'>
                                <img src={formData?.brand?.brandImage} alt="Brand logo" style={{ width: "110px", height: "110px", padding: '5px', borderRadius: "10px", border: '1px solid rgba(170, 170, 170, 0.3)' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="subtitle1" component="p" gutterBottom>
                                        <span className="label ">Campaign Name : </span>  <span className=" text-capitalize">{formData?.name}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" gutterBottom>
                                        <span className="label">Brand Name : </span>   {formData?.brandName || "-"}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p">
                                        <span className="label">Followers: </span> 50K, 100K, 300K, 1M
                                    </Typography>
                                </Box>
                            </div>

                            <div className='campDetailPrev mt-4'>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography className='mb-0' component="p" gutterBottom>
                                        {formData?.brief && <span dangerouslySetInnerHTML={{ __html: formData?.brief }} ></span>}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Campaign Type: </span>  <span className="capitalize-first-letter"> {formData?.type || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Status: </span> <span className="capitalize-first-letter">{formData?.status}</span>
                                    </Typography>
                                    {
                                        getUserType() == ADMIN &&
                                        <Typography variant="subtitle1" gutterBottom>
                                            <span className="label">Goal of the Campaign:</span> <span style={{ textTransform: "capitalize" }}>{formData?.demand || ""}</span>
                                        </Typography>
                                    }

                                    <Typography className='d-flex align-items-center' variant="subtitle1" gutterBottom>
                                        <span className="label">Platform: </span>
                                        <span>
                                            <Stack direction="row" spacing={1}>

                                                {formData?.deliverables?.length > 0 ? formData?.deliverables[0]?.plateform : ""}{" "}({formData?.deliverables?.length > 0 ? formData?.deliverables[0]?.deliverables?.map((v, index) => {
                                                    return v?.post + "-" + (v?.qty || 0) + (!(index === formData?.deliverables[0]?.deliverables?.length - 1) ? " , " : "")
                                                }) : ""})


                                            </Stack >
                                        </span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Company Name: </span>  <span>{formData?.brand?.companyName || ""}</span>
                                    </Typography>

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Products: </span> <span >{formData?.products?.map((v, index) => {
                                            return <>{v.name}{"  "}(<a style={{ wordBreak: "break-word", fontSize: '13px', lineHeight: 'normal', display: 'inline-block' }} href={v?.link} target='_blank' rel="noreferrer" >{v.link}</a>){!(index === formData.products.length - 1) && " , "}</>
                                        })}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Start Date: </span> <span>{dateForamt(formData?.startDate) || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">End Date: </span> <span>{dateForamt(formData?.endDate) || 'N/A'}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Apply Date: </span> <span>{formData?.applyBydate ? dateForamt(formData?.applyBydate) : 'N/A'}</span>
                                    </Typography>
                                    {/* <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Campaign Brief </span> <span dangerouslySetInnerHTML={{ __html: formData?.brief }} ></span>
                                    </Typography> */}
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Script Date: </span> <span>{dateForamt(formData?.conceptScriptDate) || ''}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Submission Date: </span> <span>{dateForamt(formData?.contentSubmissionDate) || ''}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Publish Date: </span> <span>{dateForamt(formData?.contentPublishDate) || ''}</span>
                                    </Typography>
                                    {
                                        formData.type !== CAMPAIGN_TYPES.BARTER && <>
                                            <Typography variant="subtitle1" gutterBottom>
                                                <span className="label">Budget: </span> <span>{formData?.budget || "N/A"}</span>
                                            </Typography></>
                                    }

                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Budget per influencer: </span> <span>{formData?.budgetPerInfluencer || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">No. of Creators: </span> <span>{formData?.noOfCreators || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Account Mentions
                                        </span>   <span>{formData?.accountMentions || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Campaign Hashtag
                                        </span>   <span>{formData?.campaignHashtag || "N/A"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label"> Reference Content
                                        </span>{formData?.referenceContent ? <span dangerouslySetInnerHTML={{ __html: formData?.referenceContent }}></span> : "N/A"}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Contact Details: </span> <span>{formData?.contactDetails || 'N/A'}</span>
                                    </Typography>


                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Influencer Type: </span> <span>{formData?.creator?.influncerType.toString() || "Any"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Gender: </span> <span>{formData?.creator?.gender || formData?.creator?.createrGender || 'Any'}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Location: </span> <span>{formData?.creator?.creatorLocation?.length > 0 ? formData?.creator?.creatorLocation?.toString() : "Any"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Categories: </span> <span>{formData?.creator?.selectedCategories?.length > 0 ? formData?.creator?.selectedCategories?.toString() : "Any"}</span>
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Applying Preferences </span> <span>Apply by Everyone ({formData?.creator?.campaignApplyingPreferences ? "YES" : "NO"})</span>
                                    </Typography>





                                </Box >
                            </div >
                        </div >
                        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='mb-xxl-0 mb-xl-0 mb-lg-5 mb-md-5 mb-sm-5 mb-5 ps-xxl-4 ps-xl-4 ps-lg-0 ps-md-0 ps-sm-0 ps-0'>
                                <img src={id !== undefined ? formData?.bannerImage?.toString() : URL.createObjectURL(formData?.bannerImage)} style={{ width: '100%', height: "auto", borderRadius: "16px" }} alt="Banner Image" />
                            </div>
                            {formData.type === CAMPAIGN_TYPES.PAID &&
                                <Card sx={{ maxWidth: 500, padding: 2, borderRadius: 2, bgcolor: "#F7F7F7", ml: 4, mt: 4 }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Milestone Payments Details
                                        </Typography>
                                        <Grid container mt={1} spacing={2}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Advance Payment</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.advancePayment || "0"}%</Typography>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Typography variant="body1">Script Approval</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.scriptApproval || "0"}%</Typography>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Typography variant="body1">Content Approval</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.contentApproval || "0"}%</Typography>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Typography variant="body1">Publish Link Approval</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">{formData?.linkApproval || "0"}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            }
                        </div>
                    </div >
                </div >

                {false && <Grid container rowGap={3} >

                    <Grid item xs={12} lg={12} md={12} sm={12} className='d-flex align-items-start justify-content-start' gap={3}>
                        <img src={formData?.brandLogo} alt="banner" style={{ width: "auto", height: "100px", borderRadius: "10px", border: '1px solid rgba(170, 170, 170, 0.3)' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label ">Campaign Name : </span>  <span className=" text-capitalize">{formData?.name}</span>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Brand Name : </span>   {formData?.brandName || "-"}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Followers: </span> 50K, 100K, 300K, 1M
                            </Typography>
                        </Box>
                    </Grid>
                    <hr />
                    <Grid container rowGap={3} >
                        <Grid className='campDetailPrev' item xs={12} lg={12} md={12} sm={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {formData?.brief && <span dangerouslySetInnerHTML={{ __html: formData?.brief }} ></span>}
                                </Typography>

                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Company Name: </span>  <span>{formData?.brand?.companyName || ""}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Products: </span> <span>{formData?.products?.map((v) => {
                                        return <>{v.name}{"  "}(<a href={v?.link} target='_blank' rel="noreferrer" >{v.link}</a>){" , "}</>
                                    })}</span>
                                </Typography>
                                {/* <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Product Name: </span>   <span>{formData?.productName || ""}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Product Link: </span><a href={formData?.productLink} target='_blank' rel="noreferrer" > <span>{formData?.productLink || ""}</span></a>
                                </Typography> */}

                                <Typography className='d-flex align-items-center' variant="subtitle1" gutterBottom>
                                    <span className="label">Deliverables: </span>
                                    <span>
                                        N/A
                                        {/* <Stack direction="row" spacing={1}>
                                            {formData?.deliverables?.find((v) => v.plateform == "instagram")?.deliverables?.length > 0 && <><InstagramIcon size="small" color="error" /> <span className='deliverables'> x {formData?.deliverables?.find((v) => v.plateform == "instagram")?.deliverables?.length}</span></>}
                                            {formData?.deliverables?.find((v) => v.plateform == "youtube")?.deliverables?.length && <><span>|</span><YouTubeIcon size="small" color="error" /> <span className='deliverables'>x {formData?.deliverables?.find((v) => v.plateform == "youtube")?.deliverables?.length}</span></>}
                                            {formData?.deliverables?.find((v) => v.plateform == "facebook")?.deliverables?.length > 0 && <><span>|</span><FacebookIcon size="small" color="info" /> <span className='deliverables'>x {formData?.deliverables?.find((v) => v.plateform == "facebook")?.deliverables?.length}</span></>}

                                        </Stack > */}
                                    </span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Additional Req: </span>  {formData?.additionalRequirement !== "null" && formData?.additionalRequirement != null ? <span dangerouslySetInnerHTML={{ __html: formData?.additionalRequirement }} ></span> : "N/A"}
                                </Typography>
                                {/* <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Type:</span> <span style={{ textTransform: "capitalize" }}>{formData?.type || ""}</span>
                                </Typography> */}
                                {getUserType() == ADMIN &&
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Goal of the Campaign:</span> <span style={{ textTransform: "capitalize" }}>{formData?.demand || ""}</span>
                                    </Typography>
                                }
                                {formData.type !== CAMPAIGN_TYPES.BARTER && <>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label">Budget: </span> <span>{formData?.budget || "N/A"}</span>
                                    </Typography></>}
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Influencer Type: </span> <span>{formData?.creator?.influncerType.toString() || "N/A"}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Gender: </span> <span>{formData?.creator?.gender || 'N/A'}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Location: </span> <span>{formData?.audience?.creatorLocation?.toString()}</span>
                                </Typography>
                                {/* <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Status: </span> <span>{formData?.status}</span>
                                </Typography> */}

                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Contact Details: </span> <span>{formData?.contactDetails || 'N/A'}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Script Date: </span> <span>{dateForamt(formData?.conceptScriptDate) || ''}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Content Date: </span> <span>{dateForamt(formData?.contentSubmissionDate) || ''}</span>
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Publish Date: </span> <span>{dateForamt(formData?.contentPublishDate) || ''}</span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>




                </Grid >}

                {
                    !id &&
                    <>
                        <hr />
                        <Grid item xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">
                            <Stack direction={"row"} spacing={1}>
                                <button
                                    className='btnSecondary'
                                    onClick={() => {
                                        dispatch(updateCampaign({ step: 6 }));
                                        navigate("/campaign/profile-screener")
                                    }}
                                >
                                    Back
                                </button>
                                <button
                                    className='btnPrimary'
                                    onClick={() => {
                                        let postData = { campaignId: String(formData.campaignId), status: "PENDING" };
                                        dispatch(updateCampaignStatus(postData));
                                        navigate("/campaign/submit-review")
                                        //setOpennew(true);
                                        //navigate("/campaign/submit-review")
                                    }}
                                >
                                    Create Campaign
                                </button>
                            </Stack>

                        </Grid>
                    </>
                }


            </Grid >
            <CommonModel
                open={openNew}
                handleDialogClose={() => setOpennew(false)}
                dialogTitle={"Create Campaign"}
                dialogPara={`Are you sure you want to create this campaign? `}
                nvYes={"Submit"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(updateCampaignStatus({ campaignId: formData?.id, status: "PENDING" }));
                    setOpennew(false);
                    navigate("/campaign/submit-review")
                }}
            />
            <CommonModel
                open={unpublishOpen}
                handleDialogClose={() => setOpenUnpublish(false)}
                dialogTitle={"Campaign unpublish"}
                dialogPara={`Are you sure you want to unpublish this campaign? `}
                nvYes={"Yes"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(updateCampaignStatus({ campaignId: String(formData?.id), status: UNPUBLISHED }));
                    setOpennew(false);
                    navigate("/campaigns")
                }}
            />

        </Box >
        {getUserType() !== ADMIN &&
            <Box
                mt={4}
                p={3}
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <Grid container>
                    <Grid item xs={12} lg={12} md={12} sm={12} >
                        <Typography variant="h4" gutterBottom>
                            Campaign Insights
                        </Typography>

                    </Grid>
                </Grid>
            </Box>
        }

    </>
}
export default CampaignDetails;