import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  exploreCampaignCount: 0,
  exploreTotalPages: 0,
  exploreLimit: 10,
  explorePage: 0,
  exploreSearch: "",
  totalPages: 0,
  listing: [],
  filters: {},
  walletDetails: {},
  startDate: "",
  endDate: "",
  companyIds: [],
  depositHistory: [],
  estimatedPayouts: {},
  exploreCampaignDetails: [],
  campaignData: {},
  pageRefresh: ""
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    updateWallet: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetWallet: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWallet, resetWallet } =
  walletSlice.actions;

export default walletSlice.reducer;
