import {
    Search
} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import {
    Avatar,
    Box,
    InputAdornment,
    LinearProgress,
    Pagination,
    Stack,
    Tooltip,
    tooltipClasses,
    Typography
} from "@mui/material";

import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddMoney from "../../components/dialog/AddMoney";
import SearchInput from "../../components/inputs/searchInput";
import PaginationSelect from "../../components/select/pagination";
import useDebounce from "../../hooks/use.debounce";
import { formatPrice, getFormatedDate, getUserType } from "../../utils/common";
import { ADMIN, COMPANY } from "../../utils/constant";
import { getAllBrandForDropDown } from "../brands/action";
import { getExploreCampaignList, getWalletDetails, getWalletEstimatedPayout } from "./action";
import DepositHistory from "./depositHistory";
import { resetWallet, updateWallet } from "./walletSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isSticky } = props;
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Campaign Name",
            type: "",
        },
        {
            id: "company",
            numeric: false,
            disablePadding: true,
            label: "Company",
            type: "",
        },
        {
            id: "paid",
            numeric: false,
            disablePadding: true,
            label: "Paid",
            type: "",
        },

        {
            id: "platform",
            numeric: false,
            disablePadding: true,
            label: "Platforms",
            type: "",
        },
        {
            id: "due",
            numeric: false,
            disablePadding: true,
            label: "Payment Due",
            type: "",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            type: "",
        },

    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort ${isSticky} `}>
            <TableRow>
                {headCells?.filter((v) => v.id !== getUserType())?.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function Wallet() {
    const navigate = useNavigate();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [paymentModalOpen, setAddPaymentOpen] = useState(false);
    const [sticky, setSticky] = useState("");
    const [tab, setTab] = useState("mywallet");
    const dispatch = useDispatch();
    const {
        exploreLimit,
        explorePage,
        sort_key,
        exploreCampaignCount,
        exploreTotalPages,
        filters,
        listing,
        walletDetails,
        estimatedPayouts,
        exploreSearch
    } = useSelector((state) => state.walletSlice);
    const debouncedSearch = useDebounce(exploreSearch, 500);
    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateWallet({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllBrandForDropDown());
    };

    useEffect(() => {

        if (debouncedSearch)
            dispatch(getExploreCampaignList())
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        dispatch(getExploreCampaignList())
        dispatch(getWalletDetails());
        dispatch(getWalletEstimatedPayout())

        return () => dispatch(resetWallet())
    }, []);

    const handleSearch = (event) => {
        if (event.target.value) {
            dispatch(updateWallet({ exploreSearch: event.target.value }));
        } else {
            dispatch(updateWallet({ exploreSearch: "" }));
            dispatch(getExploreCampaignList())
        }

    };

    const handleSearchClear = () => {
        dispatch(updateWallet({ exploreSearch: "" }));
        dispatch(getExploreCampaignList());
    };

    const handleChangePerPage = (event, value) => {
        dispatch(updateWallet({ exploreLimit: event.target.value, explorePage: 0 }));
        dispatch(getExploreCampaignList());
    };

    const handleChange = (event, value) => {
        dispatch(updateWallet({ explorePage: value - 1 }));
        dispatch(getExploreCampaignList());
    };

    const tableRef = useRef();
    // useEffect(() => {
    //   const tableContainer = tableRef.current;
    //   const handleScroll = () => {

    //     if (tableContainer) {
    //       const { scrollTop, clientHeight, scrollHeight } = tableContainer;
    //       console.log("scroll", Math.ceil(scrollTop + clientHeight), scrollHeight - 1);
    //       if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
    //         dispatch(updateWallet({ exploreLimit: exploreLimit + 10, page: 0 }));
    //         dispatch(getExploreCampaignList())
    //       }
    //     }

    //   };

    //   tableContainer.addEventListener('scroll', handleScroll);

    //   return () => {
    //     tableContainer.removeEventListener('scroll', handleScroll);
    //   };
    // });
    // const isSticky = () => {
    //   const scrollTop = window.scrollY;
    //   const stickyClass = scrollTop >= 150 ? "is-sticky" : "";
    //   setSticky(stickyClass);
    //   console.log("stickyClass", stickyClass, scrollTop);
    // };
    // useEffect(() => {
    //   console.log("hello");
    //   window.addEventListener("scroll", isSticky);
    //   return () => {
    //     window.removeEventListener("scroll", isSticky);
    //   };
    // }, []);
    return (
        <>
            <div className="pageTitle">
                <div className="row d-flex align-items-center" style={{ minHeight: '42px' }}>
                    <div className="col-lg-4 col-md-5 col-sm-4 col-12">
                        <Typography className="page-title" variant="h1"> {tab == "mywallet" ? "Wallet" : " Deposit History"}</Typography>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-8 col-12 d-flex justify-content-end">
                        <div className='dashPgeTbs'>
                            <div className='viewDetTabs dashboardPage'>
                                <button className={tab === "mywallet" && "active"}
                                    onClick={() => setTab("mywallet")}
                                >
                                    My Wallet
                                </button>
                                <button className={tab !== "mywallet" && "active"}
                                    onClick={() => setTab("depositHistory")}
                                >
                                    Deposit History
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="viewAmtBlcks">
                        {tab === "mywallet" && <>
                            {getUserType() === ADMIN ? <div className="amtblckItem">
                                <div> <h4>₹{formatPrice(walletDetails?.availableBalance)}</h4>
                                    <p>Total Balance</p>
                                    <span>
                                        (across all companies)
                                    </span>
                                </div>

                            </div> : <><div className="amtblckItem showHandCursor">
                                <div>
                                    <h4>₹{formatPrice(parseFloat(walletDetails?.availableBalance) + parseFloat(walletDetails.withdrawPendingBalance))}</h4>
                                    <p>Current Balance</p>
                                </div>
                                <div className="otherBal">
                                    <div>
                                        <h4>₹{formatPrice(walletDetails.withdrawPendingBalance)}</h4>
                                        <p>Blocked bal. <BootstrapTooltip arrow title="This is the balance which is blocked for the creators working on your live campaigns." placement="top"><InfoIcon className="blueColor" fontSize='small' color="secondary" /></BootstrapTooltip></p>
                                    </div>
                                    <div>
                                        {/* <h4>₹{formatPrice(parseFloat(walletDetails?.availableBalance) - parseFloat(walletDetails.withdrawPendingBalance))}</h4> */}
                                        <h4>₹{formatPrice(parseFloat(walletDetails?.availableBalance))}</h4>
                                        <p>Available bal. <BootstrapTooltip arrow title="This is the balance which is available for adding more influencers to your live campaigns." placement="top"><InfoIcon className="blueColor" fontSize='small' color="secondary" /></BootstrapTooltip></p>
                                    </div>
                                </div>
                            </div></>}
                            <div className="amtblckItem">
                                <div>
                                    <h4>₹{estimatedPayouts?.estimatedPayout ? formatPrice(estimatedPayouts?.estimatedPayout) : 0}</h4>
                                    <p>{getUserType() === ADMIN ? "Influencer Estimated Payout" : "Estimated Total Payout"}</p>
                                </div>

                            </div>
                            {getUserType() === COMPANY && <>
                                <div className="amtblckItem">
                                    <div>
                                        <h4>{estimatedPayouts?.creators}</h4>
                                        <p>Creator’s Live</p>
                                        <span>(across all campaigns)</span>
                                    </div>
                                </div>
                                <div className="amtblckItem addmny">
                                    <button
                                        className="btnPrimary"
                                        onClick={() => setAddPaymentOpen(true)}
                                    >
                                        Add Money
                                    </button>
                                </div>
                            </>}
                        </>}
                    </div>
                </div>
            </div>


            {tab === "mywallet" ?
                <div className="card mt-20">
                    <div className="myCmpny d-flex flex-wrap  justify-content-between searcFilter p-3">
                        <div className='allFilter cmpyMgmt '>
                            <div className="d-flex  gap-1" >
                                <Typography className="page-title" variant="h4"> Explore Campaigns</Typography>
                            </div>
                        </div>
                        <SearchInput
                            className="inputSearch sContrl p-0"
                            value={exploreSearch}
                            placeholder={"Search here..."}
                            handleSearch={handleSearch}
                            clearSearch={handleSearchClear}
                            startAdornment={
                                <InputAdornment className="overIcon" position="start"
                                    sx={{
                                        maxHeight: 'none',
                                        height: '38px',
                                        minWidth: '38px',
                                        marginRight: '0px'
                                    }}
                                >
                                    <Search />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <TableContainer sx={{ maxHeight: 550 }} ref={tableRef}>
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                // numSelected={selected.length}
                                isSticky={sticky}
                                order={order}
                                orderBy={sort_key}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                //rowCount={rows.length}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            //order={"desc"}
                            />
                            <TableBody>
                                {listing && listing?.length ? (
                                    listing?.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row?.campaignId}
                                                className={"tableRow"}
                                                style={{ userSelect: "text" }}
                                            >
                                                <TableCell component="td" scope="row">
                                                    <div className="campimg">
                                                        <Avatar
                                                            alt={row?.name}
                                                            src={row.bannerImage.length > 0 ? row?.bannerImage?.toString() : ""}
                                                            sx={{ height: '30px', width: '30px', borderRadius: "50px" }}
                                                        />
                                                        <div className="namedate">

                                                            <Link className="text-decoration-none text-capitalize" onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate("/wallet/campaign/" + row?.campaignId)
                                                            }} >
                                                                {row?.name || "N/A"}</Link>

                                                            <span> Created on: {row?.createdDate ? getFormatedDate(row?.createdDate?.toLocaleString(), "DD MMM YYYY") : "N/A"}</span>
                                                        </div>
                                                    </div>



                                                </TableCell>
                                                {getUserType() === ADMIN &&
                                                    <TableCell component="td" scope="row">
                                                        {row?.companyName || "N/A"}
                                                    </TableCell>
                                                }
                                                <TableCell component="td" scope="row">

                                                    {row?.percentage_invested ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Box sx={{ width: '200px', mr: 1 }}>
                                                            <BorderLinearProgress variant="determinate" value={row?.percentage_invested} />
                                                        </Box>
                                                        <Box sx={{ minWidth: 35 }}>
                                                            <Typography variant="body2" color="text.secondary">{`${row?.percentage_invested}%`}</Typography>
                                                        </Box>
                                                    </Box> : "N/A"}


                                                </TableCell>
                                                <TableCell component="td" scope="row" className={"capitalize-first-letter"}>
                                                    {row?.platform || "N/A"}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    <div className="paymentDue">
                                                        ₹{row?.due ? formatPrice(row?.due) : 0.00}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <Stack
                        sx={{
                            background: 'transparent',
                        }}
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                            <Box className="d-flex align-items-center rowPerPage" gap={0}>
                                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                                <PaginationSelect handleChange={handleChangePerPage} page={exploreLimit} />
                            </Box>
                            <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                                    {listing && listing.length > 0
                                        ? explorePage === 0
                                            ? `0 - ${listing.length}`
                                            : `${exploreLimit * (explorePage - 0) + 1}  - ${exploreLimit * (explorePage - 0) + listing.length
                                            } `
                                        : "0 - 0"}{" "}
                                    &nbsp;of {exploreCampaignCount} items
                                </Typography>
                                <Pagination

                                    count={Math.ceil(exploreTotalPages / exploreLimit) || 0}
                                    variant="outlined"
                                    onChange={handleChange}
                                    defaultPage={1} siblingCount={0} boundaryCount={1}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </div>

                : <DepositHistory />}




            <AddMoney open={paymentModalOpen} setOpen={setAddPaymentOpen} />
        </>
    );
}
