import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsButtons from '../../../components/buttons';
import { getUserType } from '../../../utils/common';
import { COMPANY, LIVE } from '../../../utils/constant';
import { getCampaignById } from '../action';

const PartialCampaignDetails = () => {

    const { id } = useParams()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.campaignSlice);
    useEffect(() => {
        dispatch(getCampaignById(id));
    }, [])
    return <>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={3} mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" component="h1">View Campaign Details</Typography>
                <Stack
                    className="backText"
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        if (getUserType() !== COMPANY) {
                            navigate("/campaign-management");
                        } else {
                            navigate("/my-campaigns");
                        }
                    }}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"}>
                        Back
                    </Typography>
                </Stack>
            </Stack>
            {formData.status === LIVE && <DetailsButtons />}
        </Stack>
        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Grid container>


                <Grid container rowGap={1} >

                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label ">Campaign Name : </span>  <span className=" text-capitalize">{formData?.name}</span>
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4} sm={4} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Brand Name : </span>   {formData?.brandName || "-"}
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={2} lg={2} md={2} sm={2} className='d-flex align-items-end justify-content-end' gap={2}>
                        <Typography onClick={() => navigate("/campaign/details/" + id)} variant="subtitle1" gutterBottom color={"#0077FF"} sx={{ textDecoration: "underline" }} className="showhandCursor">
                            More Info+
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Campaign Duration: </span>  {moment(formData?.startDate).format("DD MMM")}{" - "}{moment(formData?.endDate).format("DD MMM")}
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Platform: </span> {formData?.deliverables?.length > 0 ? formData?.deliverables[0].plateform : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    {formData?.products?.map((v, i) => {
                        return <> <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label ">Product Name : </span>  <span className=" text-capitalize">{v?.name}  </span>
                                </Typography>

                            </Box>
                        </Grid><Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label ">Product Link : <a href={v?.link} target='_blank' rel="noreferrer" >{v.link}</a></span>
                                    </Typography>

                                </Box>
                            </Grid></>
                    })}

                </Grid >
            </Grid >

        </Box >

        <Box
            mt={4}
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Grid container>
                <Grid item xs={12} lg={12} md={12} sm={12} >
                    <Typography variant="h4" gutterBottom>
                        Campaign Insights
                    </Typography>

                </Grid>
            </Grid>
        </Box>

    </>
}
export default PartialCampaignDetails;