import {
  AddLink,
  Edit,
  Person
} from "@mui/icons-material";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetParamMasterQuery } from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { FOLLOWERS_ARRAY, MAX_FILE_SIZE, capitalizeFirstLetter, citiesInIndia, phonePreventText } from "../utils/constant";
import { createInfluencerValidationSchema } from "../utils/schema";
import AutoSuggestion from "./autocomplete";
import NumberInput from "./inputs/normalInput/number";
import CustomTextArea from "./inputs/textarea";
import CustomizedSnackbars from "./snackbar";

// Styled Component ---------------------------------------------------------------------------------------------
const IconBtn = styled(IconButton)(({ theme, color }) => ({
  color: color || "inherit",
  padding: 1,
  marginBottom: "7px",
}));

const Flex = styled(Stack)(({ theme, sx }) => ({
  ...sx,
  flexGrow: 1,
}));

// Form Component ---------------------------------------------------------------------------------------------
const Form = ({ type = "create", data, onSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = React.useRef(null);
  const { languages, deliverablesPlatform } = useSelector((state) => state.commonSlice);
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });

  const [image, setImage] = React.useState(null);
  const [imageSend, setImageSend] = React.useState(null);
  const [parameter, setParameter] = React.useState([]);
  const { data: paramData, isSuccess } = useGetParamMasterQuery();

  React.useEffect(() => {
    if (paramData?.status == "SUCCESS") {
      setParameter(paramData?.result);
    }
    data?.picture && setImage(data.picture);
  }, [isSuccess]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      dispatch(updateCommonData({
        message: "File size exceeds 2 MB. Please upload a smaller file.",
        title: "Failed",
        img: "/failed.png",
        status: true
      }));
      event.target.value = '';
      return false;
    }


    setImageSend(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // TODO : change when email input field is added
  const generateRandomEmail = () => {
    if (type !== "create") {
      return data?.email;
    }
    const usernameLength = getRandomInt(5, 10);
    const domainLength = getRandomInt(5, 10);
    const username = generateRandomString(usernameLength);
    const domain = generateRandomString(domainLength);
    const tlds = ["com", "net", "org", "io"];
    const tld = tlds[Math.floor(Math.random() * tlds.length)];
    return `${username}@${domain}.${tld}`;
  };
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const generateRandomString = (length) => {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };
  // ----------------------------------

  const theme = useTheme();
  if (data) {
    var { platformUrls, initialProfileNicheData, ...otherParams } = data;
  }
  let defaultData = data
    ? { ...otherParams, profileNiche: initialProfileNicheData }
    : {
      name: "",
      phoneNumber: "",
      selecedLanguages: [],
      country: "",
      city: "",
      state: "",
      address: "",
      email: "",
      gender: "",
      profileNiche: [],
      influencerType: "",
      followers: "",
      rateCard: Object.fromEntries(
        Object.entries(deliverablesPlatform).map(([key, value]) => [
          key,
          value.map(type => ({
            type: type,
            price: ""
          }))
        ])
      )
    };

  //Validation Using Formik -----------------------------------------------------------------------------------------
  console.log("defaultData", defaultData)
  const formik = useFormik({
    initialValues: defaultData,
    validationSchema: createInfluencerValidationSchema,
    onSubmit: (value) =>
      handleSubmit({
        ...value,
        platform: checkboxData,
        profileNiche: value.profileNiche,
        selecedLanguages: JSON.stringify(value?.selecedLanguages),
        initialProfileNicheData: data?.initialProfileNicheData,
      }),
  });
  const [formData, setFormData] = React.useState(defaultData);
  const [checkboxData, setCheckboxData] = React.useState(platformUrls || {});

  // Responsive Property ---------------------------------------------------------------------------------------------
  const responsiveFlex = {
    direction: { md: "row", xs: "column" },
    px: { md: 10, xs: 2 },
    gap: { md: 5, xs: 3 },
  };

  // Handle Functions ---------------------------------------------------------------------------------------------
  const handleCheckboxChange = (value) => (event) => {

    if (event.target.checked) {
      setCheckboxData((prevSelectedItems) => ({
        ...prevSelectedItems,
        [value]: [{ username: "", url: "" }],
      }));
    } else {
      setCheckboxData((prevSelectedItems) => {
        let obj = { ...prevSelectedItems };
        delete obj[value];
        return obj;
      });
    }
  };

  const handlePlatformUrls = (event, item, index) => {
    setCheckboxData((prev) => {

      let obj = { ...prev };
      let arr = [...obj[item]];
      arr[index] = {
        ...arr[index],
        [event.target.name]: event.target.value,
      };
      obj[item] = arr;
      return { ...obj };
    });
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmit = (data) => {
    let {
      userId,
      email,
      profileNiche,
      location,
      followers,
      gender,
      influencerType,
      name,
      phoneNumber,
      country, state, city, address, selecedLanguages, rateCard
    } = data;

    email = generateRandomEmail();
    // if (type == "update") {
    //   profileNiche = profileNiche.join(", ");
    //   profileNiche = profileNiche.split(",").map((item) => item.trim());
    // }
    var initialProfileNicheData = data.initialProfileNicheData;
    var updatedInitialProfileNicheData = [];
    if (type == "create") {
      // for (let key of profileNiche) {
      //   updatedInitialProfileNicheData.push({
      //     option: key,
      //     image: "",
      //     bgcolor: "",
      //   });
      // }
    } else {
      const newElements = profileNiche.filter(
        (item) => !initialProfileNicheData?.some((data) => data.option === item)
      );

      const newProfileNicheData = newElements.map((option) => {
        const correspondingItem = profileNiche.find(
          (item) => item.option === option
        );
        return correspondingItem || { option: option, image: "", bgcolor: "" };
      });

      updatedInitialProfileNicheData = [
        ...initialProfileNicheData,
        ...newProfileNicheData,
      ];
    }

    location = JSON.stringify(location || []);
    profileNiche = JSON.stringify(profileNiche);
    const formData = new FormData();

    // Append each field to formData
    formData.append("photoUrl", imageSend == null ? data?.picture : imageSend);
    if (userId) formData.append("userId", userId);
    formData.append("profileNiche", profileNiche);
    formData.append("selecedLanguages", selecedLanguages || []);
    formData.append("followers", followers);
    formData.append("gender", gender);
    formData.append("influencerType", influencerType);
    formData.append("name", name);

    formData.append("country", country);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("address", address);
    formData.append("rateCard", JSON.stringify(rateCard));

    formData.append("phoneNumber", phoneNumber);
    formData.append("phoneCountryCode", "+91");
    formData.append(
      "platformUrls",
      Object.keys(checkboxData).length ? JSON.stringify(checkboxData) : "{}"
    );

    if (!Object.keys(checkboxData).length) {
      return setSnackbar({
        message: "Platform Data Needed.",
        severity: "error",
        open: true,
      });
    }
    onSubmit(formData);
  };
  // Dropdown Options ---------------------------------------------------------------------------------------------
  const options = {
    influencerNiche: ["ahfkj", "askhfkja", "aksjfhlkjasdg"],
    influencerType: [],
    gender: ["Male", "Female", "Others"],
    followers: ["2k-10K", "10K-25K", "25K-100K", "100K-250K"],
    platforms: [],
  };
  if (parameter?.length) {
    for (let key of parameter) {
      if (key.value && options[key.parameterkey]) {
        options[key.parameterkey] = JSON.parse(key.value);
      }
    }
  }


  const handleCheckboxChanges = (selectedOption, type) => {
    if (type == "profileNiche") {
      if (
        !formData.profileNiche.some(
          (item) => item.option === selectedOption.option
        )
      ) {
        setFormData({
          ...formData,
          profileNiche: [...formData.profileNiche, selectedOption],
        });
      } else {
        setFormData({
          ...formData,
          profileNiche: formData.profileNiche.filter(
            (item) => item.option !== selectedOption.option
          ),
        });
      }
    } else {
      if (!formData.location.some((item) => item === selectedOption)) {
        setFormData({
          ...formData,
          location: [...formData.location, selectedOption],
        });
      } else {
        setFormData({
          ...formData,
          location: formData.location.filter((item) => item !== selectedOption),
        });
      }
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setFormData({
      ...formData,
      profileNiche: formData.profileNiche.filter(
        (chip) => chip.option !== chipToDelete.option
      ),
    });
  };
  return (
    <Grid
      container
      component={"form"}
      onSubmit={formik.handleSubmit}
    >
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />{" "}
      <Grid
        item
        px={3}
        py={3}
        md={3}
        xs={12}
        className=""
        sx={{ borderRight: "1px solid rgba(170, 170, 170, 0.3)", background: 'rgba(249, 249, 249, 0.7)' }}
      >
        <FormLabel required>Upload Image</FormLabel>
        <label htmlFor="image" className="w-100">
          <Stack className="imgUploadUI">
            <Typography component="div">
              <input type="file" id="image" hidden onChange={handleImageChange} />
              <PhotoOutlinedIcon />
              <Typography variant="h5" component="h5">Upload a File</Typography>
              <Typography variant="p" component="small" style={{ fontSize: '13px', opacity: '0.7' }}>Image size less then 2MB</Typography>
            </Typography>
          </Stack>
        </label>
        <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 180x180 and 300x300.</small>
        {/* <Typography component="h6" variant="h6" className="fs-6" mb={0}>abc.jpg</Typography> */}

        {image && <>
          <FormLabel className="mt-4">Image Preview</FormLabel>
          <Stack>
            <img style={{ borderRadius: '8px', width: 'auto', height: 'auto' }} src={image} alt="Preview" />
          </Stack>
          {/* <Typography component="h6" variant="h6" style={{fontSize:'13px'}} mb={0}>{imageSend?.name}</Typography> */}

        </>}
        {false && <Box
          component={IconButton}
          disableRipple
          sx={{
            width: { xs: "100%", lg: "100%" },
            aspectRatio: "1/1",
            position: "relative",
            padding: '0px',
            borderRadius: "10px",
            background: theme.palette.grey[100],
            border: `1px dashed ${theme.palette.grey[300]}`,
          }}
        >
          {image ? (
            <>
              <input
                type="file"
                accept="image/*"
                id="image"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label
                htmlFor="image-upload"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={image}
                  alt="Uploaded"
                  className="upldImg"
                  style={{ width: "100%", height: "auto" }}
                />
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={handleClick}
                  sx={{
                    position: "absolute",
                    bottom: "20px",
                    right: "-20px",
                    color: "white",
                    background: "#0077FF",
                  }}
                >
                  <Edit />
                </IconButton>
              </label>
            </>
          ) : (
            <React.Fragment>
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                id="image-upload"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label
                htmlFor="image-upload"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ cursor: "pointer" }}
                  component={"svg"}
                  width="50"
                  height="38"
                  viewBox="0 0 45 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M41.5385 0H3.46154C2.54348 0 1.66303 0.31607 1.01386 0.87868C0.364697 1.44129 0 2.20435 0 3V30C0 30.7957 0.364697 31.5587 1.01386 32.1213C1.66303 32.6839 2.54348 33 3.46154 33H41.5385C42.4565 33 43.337 32.6839 43.9861 32.1213C44.6353 31.5587 45 30.7957 45 30V3C45 2.20435 44.6353 1.44129 43.9861 0.87868C43.337 0.31607 42.4565 0 41.5385 0ZM41.5385 3V22.2656L35.8983 17.3794C35.5769 17.1007 35.1952 16.8797 34.7752 16.7289C34.3552 16.5781 33.905 16.5005 33.4504 16.5005C32.9957 16.5005 32.5455 16.5781 32.1255 16.7289C31.7055 16.8797 31.3238 17.1007 31.0024 17.3794L26.6755 21.1294L17.1563 12.8794C16.5072 12.3172 15.627 12.0014 14.7094 12.0014C13.7917 12.0014 12.9116 12.3172 12.2625 12.8794L3.46154 20.5069V3H41.5385ZM3.46154 24.75L14.7115 15L32.0192 30H3.46154V24.75ZM41.5385 30H36.9151L29.1267 23.25L33.4536 19.5L41.5385 26.5088V30ZM25.9615 11.25C25.9615 10.805 26.1138 10.37 26.3991 9.99997C26.6843 9.62996 27.0898 9.34157 27.5642 9.17127C28.0386 9.00097 28.5606 8.95642 29.0642 9.04323C29.5678 9.13005 30.0304 9.34434 30.3934 9.65901C30.7565 9.97368 31.0038 10.3746 31.104 10.811C31.2041 11.2475 31.1527 11.6999 30.9562 12.111C30.7597 12.5222 30.427 12.8736 30 13.1208C29.5731 13.368 29.0712 13.5 28.5577 13.5C27.8691 13.5 27.2088 13.2629 26.7219 12.841C26.2351 12.419 25.9615 11.8467 25.9615 11.25Z"
                    fill="#E9E9E9"
                  />
                </Box>
                <IconButton
                  onClick={handleClick}
                  disableRipple
                  disableFocusRipple
                  sx={{
                    position: "absolute",
                    bottom: "20px",
                    right: "-20px",
                    color: "white",
                    background: "#0077FF",
                  }}
                >
                  <Edit />
                </IconButton>
              </label>
            </React.Fragment>
          )}
        </Box>}


      </Grid>
      <Grid item md={9} xs={12} px={5} py={4}>
        <Flex gap={2} className="">
          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>
            <FormControl fullWidth>
              <FormLabel required>Name</FormLabel>
              <OutlinedInput
                onChange={formik.handleChange}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              {formik.touched.name && (
                <FormHelperText variant="standard" error>
                  {formik.errors.name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormLabel required>Phone no.</FormLabel>
              <NumberInput
                name={"phoneNumber"}
                isRequired={true}
                type={"number"}
                focused={false}
                label={("")}
                sizeval="medium"
                values={formik.values.phoneNumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    formik.handleChange(e);
                  } else {
                    e.preventDefault();
                  }
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.phoneNumber) && formik.errors.phoneNumber}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
              {/* <OutlinedInput
                name="phoneNumber"
                value={formik.values.phoneNumber}
                type="number"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                onChange={formik.handleChange}
              /> */}
              {/* {formik.touched.phoneNumber && (
                <FormHelperText variant="standard" error>
                  {formik.errors.phoneNumber}
                </FormHelperText>
              )} */}
            </FormControl>

            {false && <FormControl fullWidth>
              <FormLabel required>Location</FormLabel>
              <AutoSuggestion
                multiple={true}
                disableClearable={true}
                values={formData.location}
                className="auto-chips-cover"
                label={""}
                onChange={(_e, newValue) => {
                  formik.handleChange({
                    target: { name: "location", value: newValue },
                  });
                }}
                placeholder="Ex. Mumbai, Delhi"
                defaultValue={formData.location}
                id={`users-${formData.location?.length}`}
                size="small"
                name="creatorLocation"
                error={Boolean(formik.touched.creatorLocation) && formik.errors.creatorLocation}
                helperText={formik.touched.creatorLocation && formik.errors.creatorLocation}
                options={citiesInIndia?.filter((v) => v !== " Pan India")}
                isOptionEqualToValue={(option, value) => {
                  if (
                    value === "" ||
                    option === "" ||
                    value === option
                  )
                    return option;
                }}
                getOptionLabel={(option) =>
                  option || "no_option_found"
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                        fontFamily: "Poppins",
                      }}
                      {...props}
                    >
                      {option}
                    </Box>
                  );
                }}
              />
              {formik.errors.location && formik.touched.location && (
                <p
                  className="error"
                >
                  {`${formik.errors?.location}`}
                </p>
              )}
              {/* <Select
                multiple

                name="location"
                placeholder="Select"
                value={formData.location}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                error={formik.touched.location && Boolean(formik.errors.location)}
                helperText={formik.touched.location && formik.errors.location}
                // error={
                //   formik.touched.location &&
                //   !formik.values.location.length
                // }
                // helperText={
                //   formik.touched.location && formik.errors.location
                // }
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Stack>
                )}
              >
                {sortedCities
                  .map((name, index) => (
                    <MenuItem
                      key={index}
                      value={name}
                      onDoubleClick={(e) => e.preventDefault()}
                      onClick={(e) => handleCheckboxChanges(name, "other")}
                    >
                      <Checkbox
                        checked={formData.location.some(
                          (item) => item === name
                        )}
                      />
                      {name}
                    </MenuItem>
                  ))}
              </Select>
              {formik.errors.location && formik.touched.location && (
                <p
                  className="error"
                >
                  {`${formik.errors?.location}`}
                </p>
              )} */}
            </FormControl>}
          </Flex>
          <Flex gap={responsiveFlex.gap} direction={responsiveFlex.direction}>
            <FormControl fullWidth >
              <FormLabel required>Gender</FormLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={formik.values.gender}
                name="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
                displayEmpty
                sx={{
                  "& .MuiSelect-select": {
                    color:
                      !formData.influencerType && theme.palette.text.disabled,
                  },
                }}
              >
                <MenuItem value="" disabled hidden>
                  Select
                </MenuItem>
                {options.gender.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.gender && (
                <FormHelperText variant="standard" error>
                  {formik.errors.gender}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth >
              <FormLabel required>Influencer Niche (select upto 3)</FormLabel>

              <AutoSuggestion
                multiple={true}
                disableClearable={true}
                values={formik.values.profileNiche}
                className="auto-chips-cover"
                label={""}
                onChange={(_e, newValue) => {
                  formik.handleChange({
                    target: { name: "profileNiche", value: newValue },
                  });
                }}
                //disabled={formik.values.profileNiche?.length <= 3 ? false : true}
                placeholder="Influencer Niche"
                defaultValue={formik.values.profileNiche}
                id={`users-${formik.values.profileNiche?.length}`}
                size="small"
                name="profileNiche"
                getOptionDisabled={(option) => formik.values.profileNiche.length >= 3 && !formik.values.profileNiche.includes(option.option)}

                error={Boolean(formik.touched.profileNiche) && formik.errors.profileNiche}
                helperText={formik.touched.profileNiche && formik.errors.profileNiche}
                options={options.influencerNiche.filter((v) => !formik.values.profileNiche.map((v) => v?.option).includes(v?.option))}
                isOptionEqualToValue={(option, value) => {
                  if (
                    value === "" ||
                    option === "" ||
                    value === option
                  )
                    return option;
                }}
                getOptionLabel={(option) =>
                  option.option || "no_option_found"
                }
                renderOption={(props, option) => {
                  console.log("props", formik.values.profileNiche.length)
                  return (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                        fontFamily: "Poppins",
                      }}
                      {...props}
                    >
                      {option.option}
                    </Box>
                  );
                }}
              />



              {/* <Select
                multiple
                //required={true}
                name="profileNiche"
                placeholder="Select"
                value={formData.profileNiche}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={
                //   formik.touched.profileNiche &&
                //   !formik.values.profileNiche.length
                // }
                // helperText={
                //   formik.touched.profileNiche && formik.errors.profileNiche
                // }
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((value) => (
                      <Chip
                        key={value.option}
                        label={value.option}
                      // onDelete={() => handleDeleteChip(value)}
                      // deleteIcon={<Cancel />}
                      />
                    ))}
                  </Stack>
                )}
              >
                {options.influencerNiche.map((name, index) => (
                  <MenuItem
                    key={index}
                    value={name}
                    onDoubleClick={(e) => e.preventDefault()}
                    onClick={(e) => handleCheckboxChanges(name, "profileNiche")}
                  >
                    <Checkbox
                      checked={formData.profileNiche.some(
                        (item) => item.option === name.option
                      )}
                    />
                    {name.option}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.profileNiche && formik.touched.profileNiche && (
                <p
                  className="error"
                >
                  {`${formik.errors?.profileNiche}`}
                </p>
              )} */}
              {/* {formik.touched.profileNiche && (
                <FormHelperText variant="standard" error>
                  {formik.errors.profileNiche}
                </FormHelperText>
              )} */}
            </FormControl>
          </Flex>
          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>
            <FormControl fullWidth>
              <FormLabel required>Platform</FormLabel>
              <Flex
                className="platBlocks"
                direction={"row"}
                flexWrap={"wrap"}
                justifyContent={"start"}
              >
                {options.platforms?.map((option) => {
                  return (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={Object.keys(checkboxData).includes(option)}
                          onChange={handleCheckboxChange(option)}
                        />
                      }
                      label={option}
                    />
                  );
                })}
              </Flex>
            </FormControl>
            <FormControl fullWidth >
              <FormLabel required>Followers</FormLabel>
              <Select
                value={formik.values.followers}
                name="followers"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.handleChange({
                    target: {
                      name: "influencerType",
                      value: FOLLOWERS_ARRAY.find((v) => v.value == e.target.value).newValue
                    }
                  })
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.followers && Boolean(formik.errors.followers)
                }
                helperText={formik.touched.followers && formik.errors.followers}
                displayEmpty
                sx={{
                  "& .MuiSelect-select": {
                    color:
                      !formData.influencerType && theme.palette.text.disabled,
                  },
                }}
              >
                <MenuItem value="" disabled hidden>
                  Select Range
                </MenuItem>
                {FOLLOWERS_ARRAY.map((item, i) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.followers && (
                <FormHelperText variant="standard" error>
                  {formik.errors.followers}
                </FormHelperText>
              )}
            </FormControl>
            {false && <FormControl fullWidth >
              <FormLabel required>Influencer Type</FormLabel>
              <Select
                //sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                value={formik.values.influencerType}
                name="influencerType"
                variant="outlined"
                onChange={(e) => {
                  formik.handleChange(e);
                  // formik.handleChange({target:{
                  //   name:"followers",
                  //   value:
                  // }})
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.influencerType &&
                  Boolean(formik.errors.influencerType)
                }
                helperText={
                  formik.touched.influencerType && formik.errors.influencerType
                }
                disableUnderline={false}
                displayEmpty
              // sx={{
              //   "& .MuiSelect-select": {
              //     color:
              //       !formData.influencerType && theme.palette.text.disabled,
              //   },
              // }}
              >
                <MenuItem value="" disabled hidden>
                  Select
                </MenuItem>
                {options.influencerType.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.influencerType && (
                <FormHelperText variant="standard" error>
                  {formik.errors.influencerType}
                </FormHelperText>
              )}
            </FormControl>}
          </Flex>

          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>

            <FormControl fullWidth>
              <FormLabel>Address</FormLabel>
              {/* <OutlinedInput
                onChange={formik.handleChange}
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              /> */}
              <CustomTextArea
                name={"address"}
                type={"text"}
                focused={false}
                label={("")}
                sizeval="medium"
                values={formik.values.address}
                onChange={formik.handleChange}
                placeholder=" "
                multiline={true}
                style={{ m: 0, p: 0, height: '50px !important' }}
              />
              {formik.touched.address && (
                <FormHelperText variant="standard" error>
                  {formik.errors.address}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>City</FormLabel>
              <OutlinedInput
                onChange={formik.handleChange}
                name="city"
                value={formik.values.city}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
              {formik.touched.city && (
                <FormHelperText variant="standard" error>
                  {formik.errors.city}
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>

            <FormControl fullWidth>
              <FormLabel>State</FormLabel>
              <OutlinedInput
                onChange={formik.handleChange}
                name="state"
                value={formik.values.state}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
              {formik.touched.state && (
                <FormHelperText variant="standard" error>
                  {formik.errors.state}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Country</FormLabel>
              <OutlinedInput
                onChange={formik.handleChange}
                name="country"
                value={formik.values.country}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
              {formik.touched.country && (
                <FormHelperText variant="standard" error>
                  {formik.errors.country}
                </FormHelperText>
              )}
            </FormControl>

          </Flex>
          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>
            <FormControl fullWidth>
              <FormLabel >Languages</FormLabel>
              <AutoSuggestion
                multiple={true}
                disableClearable={true}
                values={formData.selecedLanguages}
                className="auto-chips-cover"
                label={""}
                onChange={(_e, newValue) => {
                  formik.handleChange({
                    target: { name: "selecedLanguages", value: newValue },
                  });
                }}
                placeholder="Ex. English"
                defaultValue={formData.selecedLanguages}
                id={`users-${formData.selecedLanguages?.length}`}
                size="small"
                name="selecedLanguages"
                options={languages}
                isOptionEqualToValue={(option, value) => {
                  if (
                    value === "" ||
                    option === "" ||
                    value === option
                  )
                    return option;
                }}
                getOptionLabel={(option) =>
                  option || "no_option_found"
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                        fontFamily: "Poppins",
                      }}
                      {...props}
                    >
                      {option}
                    </Box>
                  );
                }}
              />


            </FormControl>
            <FormControl fullWidth></FormControl>
          </Flex>
          <hr></hr>
          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>
            <Typography variant="h4">Rate Card</Typography>
          </Flex>
          <Flex direction={responsiveFlex.direction} gap={responsiveFlex.gap}>
            {console.log("rate card", formik.values?.rateCard)}
            {Object.entries(deliverablesPlatform).map(([key, value]) => {
              return <FormControl key={key} fullWidth>
                <Typography >
                  {key === "youtube" && <YouTubeIcon size="small" color='error' />}
                  {key === "facebook" && <FacebookOutlinedIcon size="small" color='primary' />}
                  {key === "instagram" && <InstagramIcon size="small" color='error' />} {capitalizeFirstLetter(key)}</Typography>
                {value.map((v, i) => {

                  return <>
                    <FormLabel key={v} sx={{ mt: 2 }} >{v}</FormLabel>
                    <NumberInput
                      name={`rateCard[${key}][${i}]['price']`}
                      type={"number"}
                      key={v}
                      focused={false}
                      isRequired={false}
                      label={""}
                      placeholder={"₹1000"}
                      sizeval="medium"
                      values={formik.values?.rateCard?.[key]?.[i]?.['price'] || ""}
                      onKeyDown={(e) =>
                        phonePreventText.includes(e.key) &&
                        e.preventDefault()
                      }

                      onChange={(e) => {
                        console.log("kasif", `rateCard[${key}][${i}]['price']`)
                        formik.handleChange({
                          target: {
                            name: `rateCard[${key}][${i}]['price']`,
                            value: e.target.value
                          }
                        })
                        formik.handleChange({
                          target: {
                            name: `rateCard[${key}][${i}]['type']`,
                            value: v
                          }
                        })
                        console.log("formik.values?.rateCard?.[key]?.[i]", formik.values?.rateCard?.[key]?.[i])
                        if (formik.values?.rateCard?.[key]?.[i] === undefined) {
                          formik.setFieldValue(`rateCard[${key}][${i}]`, { type: v, price: 0 })
                        }


                      }}
                    />
                  </>
                })}
              </FormControl>
            })}
            <FormControl fullWidth></FormControl>
          </Flex>

          {console.log("rateCard", formik.values?.rateCard)}

          {Object.keys(checkboxData).length > 0 && (
            <Flex
              p={3}
              mt={3}
              sx={{ background: "rgba(127, 76, 199, 0.05)", borderRadius: '10px' }}
            >
              <Flex direction={"column"} gap={2}>
                <Typography variant="h4">Add Platform URL's</Typography>
                {Object.keys(checkboxData).map((item) => (
                  <>
                    {checkboxData[item]?.map((checkboxItem, index) => (
                      <Flex
                        direction={responsiveFlex.direction}
                        gap={3}
                        key={index}
                      >
                        <FormControl fullWidth sx={{ gap: 0.3 }}>
                          <FormLabel required>{`${item} Username`}</FormLabel>
                          <OutlinedInput
                            required
                            endAdornment={
                              <InputAdornment position="end">
                                <Person />
                              </InputAdornment>
                            }
                            onChange={(e) => handlePlatformUrls(e, item, index)}
                            name="username"
                            value={checkboxItem.username}
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ gap: 0.3 }}>
                          <FormLabel required>{item} URL</FormLabel>
                          <OutlinedInput
                            required
                            endAdornment={
                              <InputAdornment position="end">
                                <AddLink />
                              </InputAdornment>
                            }
                            onChange={(e) => handlePlatformUrls(e, item, index)}
                            name="url"
                            value={checkboxItem.url}
                          />
                        </FormControl>
                        <Flex direction={responsiveFlex.direction} gap={2}>
                          <Flex
                            direction={"row"}
                            gap={1}
                            alignItems={"end"}
                            justifyContent={"center"}
                            mb={1}
                          >
                            <>
                              <img src="/plus.png" style={{ width: '28px', height: '28px', cursor: 'pointer' }}
                                onClick={() =>
                                  setCheckboxData((prev) => {
                                    const newItem = { username: "", url: "" };
                                    const updatedItemArray = [...prev[item], newItem];
                                    return { ...prev, [item]: updatedItemArray };
                                  })
                                  // setCheckboxData((prev) => {
                                  //   prev[item].push({ username: "", url: "" });
                                  //   return { ...prev };
                                  // })

                                }
                                alt="plus"
                              />
                              <img src="/minus.png" style={{ width: '28px', height: '28px', cursor: 'pointer' }}
                                onClick={() =>
                                  setCheckboxData((prev) => {
                                    if (prev[item]?.length > 1) {
                                      prev[item].splice(index, 1);
                                      return { ...prev };
                                    } else {
                                      delete prev[item];
                                      return { ...prev };
                                    }
                                  })
                                }
                                alt="minus"
                              />
                            </>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </>
                ))}
              </Flex>
            </Flex>
          )}
          <Flex mt={1.5} alignItems={{ md: "flex-end", xs: "center" }}>
            {type != "create" ? (
              <Flex direction={"row"} gap={1}>
                <button className="btnSecondary" onClick={() => navigate(-1)}>Cancel</button>
                <button className="btnPrimary" type="submit">Update</button>
              </Flex>
            ) : (
              <button className="btnPrimary" type="submit">Create New User</button>
            )}
          </Flex>
        </Flex>
      </Grid>
    </Grid>
  );
};

export default Form;
