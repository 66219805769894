import styled from "@emotion/styled";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Image from 'react-bootstrap/Image';
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import logoIcon from "../assets/images/logoIcon.svg";
import audits from "../assets/images/sideIcons/audits.png";
import brands from "../assets/images/sideIcons/brands.png";
import campaign from "../assets/images/sideIcons/campaign.png";
import company from "../assets/images/sideIcons/company.png";
import dashboard from "../assets/images/sideIcons/dashboard.png";
import mycampaigns from "../assets/images/sideIcons/mycampaign.png";
import privacy from "../assets/images/sideIcons/privacy.png";
import roles from "../assets/images/sideIcons/roles.png";
import subscription from "../assets/images/sideIcons/subscription1.png";
import usermgt from "../assets/images/sideIcons/usermgt.png";
import users from "../assets/images/sideIcons/users.png";
import wallet from "../assets/images/sideIcons/wallet.png";
import { getUserInfoNew } from "../layout/action";
import { getCompanyDetails } from "../pages/company/action";
import { setSidebar } from "../redux/reducer";
import { getCompanyid, getCompanyName, getUserType } from "../utils/common";
import { COMPANY } from "../utils/constant";
import { ROUTES } from "../utils/routes";
const sidebarData = [
  {
    title: "Dashboard",
    endpoint: "/",
    type: "dashboard",
    icon: dashboard,
    userType: ["admin", "brand", "company"],
    isActive: ["dashboard"],
    items: []
  },
  {
    title: "Wallet",
    endpoint: "/wallet",
    type: "wallet",
    icon: wallet,
    userType: ["admin", "brand", "company"],
    isActive: ["wallet"],
    items: []
  },
  {
    title: "Creator Management",
    endpoint: ROUTES.USER_MANAGEMENT,
    icon: usermgt,
    type: "users",
    userType: ["admin"],
    isActive: ["userManagement", 'createInfluencer', 'editInfluencer'],
    items: []
  },
  {
    title: "My Campaigns",
    endpoint: ROUTES.MY_CAMPAIGNS,
    icon: mycampaigns,
    userType: ["brand", "company"],
    type: "myCampaign",
    isActive: ["my-campaign", "campaign"],
    items: []
  },
  {
    title: "Company Management",
    endpoint: ROUTES.COMPANIES,
    icon: company,
    type: "company",
    userType: ["admin",],
    isActive: ["companies", "company"],
    items: []
  },

  {
    title: "Brands",
    endpoint: ROUTES.BRANDS_FOR_COMPANY,
    icon: brands,
    type: "brand",
    userType: ["brand", "company"],
    isActive: ["brands"],
    items: []
  },
  {
    title: "Campaign Management",
    endpoint: ROUTES.CAMPAIGN_MANAGEMENT,
    icon: campaign,
    type: "campaign",
    userType: ["admin"],
    isActive: ["campaign"],
    items: []
  },

  {
    title: "Admin User",
    endpoint: ROUTES.ADMIN_USERS,
    icon: users,
    type: "adminUsers",
    userType: ["admin"],
    isActive: ["adminUsers"],
    items: []
  },
  {
    title: "Roles & Rights Mgt.",
    endpoint: ROUTES.ROLES_AND_RIGHTS,
    icon: roles,
    userType: ["admin"],
    type: "roleAndRights",
    isActive: ["roles"],
    items: []
  },
  {
    title: "Subscription  Mgt.",
    endpoint: ROUTES.SUBSCRIPTION_MGT,
    icon: subscription,
    type: "roleAndRights",
    userType: ["admin"],
    isActive: ["subscriptions", "subscription"],
    items: [
      // {
      //   title: "History",
      //   endpoint: ROUTES.SUBSCRIPTION_MGT,
      //   icon: subscription,
      // }
    ]
  },
  {
    title: "Subscriptions",
    //endpoint: ROUTES.SUBSCRIPTION_FOR_COMPANY,
    icon: subscription,
    type: "roleAndRights",
    userType: ["company"],
    isActive: ["subscriptions", "subscription"],
    items: [
      {
        title: "Subscription Plan",
        endpoint: ROUTES.SUBSCRIPTION_FOR_COMPANY,
        isActive: ['active'],
        icon: subscription,
      },
      {
        title: "Subscription History",
        endpoint: ROUTES.SUBSCRIPTION_HISTORY,
        isActive: ['history'],
        icon: subscription,
      }
    ]
  },
  {
    title: "Audit Trail",
    endpoint: ROUTES.AUDIT_TRAILS,
    icon: audits,
    type: "auditTrail",
    userType: ["admin"],
    isActive: ["auditTrails"],
    items: [

    ]
  },
  {
    title: "Privacy policy",
    endpoint: ROUTES.PRIVACY_POLICY,
    icon: privacy,
    type: "privacyPolicy",
    userType: ["admin"],
    isActive: ["privacyPolicy"],
    items: []
  },
  // {
  //   title: "Notifications",
  //   endpoint: "/group-notification",
  //   icon: privacy,
  //   type: "notifications",
  //   userType: ["admin", "brand", "company"],
  //   isActive: ["notifications"],
  //   items: [
  //     {
  //       title: "Notification Groups",
  //       endpoint: ROUTES.SUBSCRIPTION_MGT,
  //       icon: subscription,
  //     },
  //     {
  //       title: "Notification History",
  //       endpoint: ROUTES.SUBSCRIPTION_MGT,
  //       icon: subscription,
  //     },
  //     {
  //       title: "Notification Editor",
  //       endpoint: ROUTES.SUBSCRIPTION_MGT,
  //       icon: subscription,
  //     }
  //   ]
  // },
];

const HoveredList = styled(List)(({ theme, sx }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  overflowY: "auto",
  zIndex: '99',
  padding: '0px 16px',
  transition: `"all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}"`,

  overflowX: "hidden",
  ...sx,
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0px 3px",
  borderRadius: "15px",

  color: "#6C7692",
  position: "relative",
  "&:hover, &.active": {
    background: 'rgba(0, 119, 255, 0.10)',
    color: "rgba(0, 119, 255, 1)",
    "& .listItemIcon": {
      color: "rgba(0, 119, 255, 1)",
    },
  },
}));

const Sidebar = () => {
  const theme = useTheme();
  const states = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.commonSlice);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState("");
  const [type, setType] = useState(-1);

  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("currentTarget", event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userType = JSON.parse(localStorage.getItem("user"));
  let sideBarJson = [];
  if (userType?.userType === "company") {
    sideBarJson = {
      brands: { edit: 1, view: 1, create: 1, delete: 1 },
      users: { edit: 1, view: 1, create: 1, delete: 1 },
      myCampaigns: { edit: 1, view: 1, create: 1, delete: 1 },
      dashboard: { edit: 1, view: 1, create: 1, delete: 1 },
      searchInculencer: { edit: 1, view: 1, create: 1, delete: 1 },
    }
  }
  else {
    sideBarJson = sidebarData.reduce((acc, item) => {
      const type = item.type;
      acc[type] = { edit: 1, view: 1, create: 1, delete: 1 };
      return acc;
    }, {});
  }
  const handleToggleSidebar = (e) => {
    e.preventDefault();
    dispatch(setSidebar());
  };

  useEffect(() => {
    dispatch(getUserInfoNew(userType?.id));
  }, []);

  return (
    states.permissions && ( //states.permissions.users
      <>
        <HoveredList
          className={`sidebar ${!states.sidebar ? 'activeSidebar' : ''}`}
          sx={{
            maxWidth: states.sidebarWidth,
            width: "100%",
            backgroundColor: "background.paper",
            zIndex: 1000,
          }}
        >
          <Link className="sidebarLogo">
            <Box className="fullLogo" component="img" src={logo}></Box>
            <Box className="logoIcon" component="img" src={logoIcon}></Box>
          </Link>
          <div className="closeSidebar" onClick={handleToggleSidebar} >
            <KeyboardDoubleArrowLeftIcon />
          </div>

          {states.permissions && sidebarData.map((item, index) =>
            states.permissions?.[item.type]?.view !== 0 &&
            item?.userType?.includes(JSON.parse(localStorage.getItem("user"))?.userType) && (
              <React.Fragment key={index}>
                <ListItem onClick={() => {
                  if (item.items.length > 0) {
                    // Toggle open state for sub-menu
                    setType(index);
                    setOpen((prev) => !prev);
                  } else {
                    setType(-2)
                    // Redirect if there are no child items
                    navigate(item.endpoint);
                  }
                }}>
                  <StyledListItem
                    className={item?.isActive?.includes(location?.pathname?.split("/")[1]) ? "active" : ""}
                    disableRipple
                    component={item.items.length > 0 ? 'div' : NavLink} // Use div for parent with children, NavLink for others
                    to={item.items.length === 0 ? item.endpoint : false} // Set to only if there are no child items
                  >
                    <ListItemIcon className="listItemIcon">
                      <Image src={item.icon} className='img-fluid' alt="" />
                    </ListItemIcon>
                    <ListItemText className="tabText m-0" disableTypography primary={item.title} />
                    {item.items.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
                  </StyledListItem>
                </ListItem>
                {
                  item.items.length > 0 && (open || (type == -1 && item?.isActive?.includes(location?.pathname?.split("/")[1]))) && (
                    <Collapse className="dropParent" in={open || (type == -1 && item?.isActive?.includes(location?.pathname?.split("/")[1]))} timeout="auto" unmountOnExit>
                      {item.items.map((v, subIndex) => (
                        <List component="div" className="dropIt" disablePadding key={subIndex}>
                          <ListItemButton sx={{ pl: 6 }} className={v?.isActive?.includes(location?.pathname?.split("/")?.slice(-1)?.pop()) ? "activeItem" : ""} onClick={() => navigate(v.endpoint)}>
                            {/* <ListItemIcon className="listItemIcon" sx={{ width: "15px" }}>
                              <Image src={v.icon} className='img-fluid' alt="" />
                            </ListItemIcon>&nbsp; */}
                            <div className="bulletIcon"></div>
                            <div className="textSubTab">{v.title}</div>
                          </ListItemButton>
                        </List>

                      ))}
                    </Collapse>
                  )
                }
              </React.Fragment>
            )
          )}



          <ListItem
            className="versionControl"
            sx={{
              paddingX: "16px",
              paddingY: "16px",
              display: "flex",
              bottom: "0px",
              justifyContent: "left",
              position: "absolute",
              transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
            }}
          >
            {false && <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Account settings">
                <IconButton
                  onMouseEnter={handleClick}
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open1 ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? 'true' : undefined}
                >
                  <Avatar alt={getCompanyName()} src={userInfo?.logo || userInfo?.companyName} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open1}
                onMouseLeave={handleClose}
                onClose={handleClose}
                onClick={handleClose}

                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      //transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'bottom', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'right' }}
              >
                {getUserType() === COMPANY && <>
                  <MenuItem onClick={() => {
                    handleClose();
                    navigate(ROUTES.SUBSCRIPTION_FOR_COMPANY)
                  }}>
                    <ListItemIcon>
                      <CardMembershipIcon fontSize="small" />
                    </ListItemIcon> View Subscription
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose();
                    navigate(ROUTES.COMPANY_USERS)

                  }}>
                    <ListItemIcon>
                      <GroupIcon fontSize="small" />
                    </ListItemIcon>Manage Users
                  </MenuItem>

                  <MenuItem onClick={() => {
                    handleClose();
                    dispatch(getCompanyDetails(getCompanyid(), navigate))
                  }}>
                    <ListItemIcon>
                      <PermIdentityIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                </>}

                <MenuItem onClick={() => {
                  handleClose();
                  localStorage.clear();
                  navigate(ROUTES.LOGIN)
                }}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              <Stack direction="column">
                <Typography
                  variant="subtitle1"
                  className="username text-capitalize"
                  fontSize="15px"
                  fontWeight={600}
                >

                  {userType?.userType === "company" ? userInfo?.companyName || userInfo?.contactPersonName : userInfo?.adminName}
                </Typography>
                <Typography
                  className="userCat text-capitalize"
                  variant="subtitle2"
                  fontWeight={500}
                  fontSize="12px"
                  sx={{ color: "#07C325" }}
                >
                  {getUserType()}
                </Typography>
              </Stack>
            </Stack>}
          </ListItem>
        </HoveredList >

      </>
    )
  );
};

export default Sidebar;
