import {
    Search
} from "@mui/icons-material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import {
    Box,
    InputAdornment,
    Pagination,
    Stack,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DateRangePickerComponent from "../../components/date/DateRangePickerComponent";
import SearchInput from "../../components/inputs/searchInput";
import PaginationSelect from "../../components/select/pagination";
import useDebounce from "../../hooks/use.debounce";
import { formatPrice, getFormatedDate } from "../../utils/common";
import { getAllBrandForDropDown } from "../brands/action";
import { getExploreCampaignDetails } from "./action";
import { resetWallet, updateWallet } from "./walletSlice";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isSticky } = props;
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Influencer Name",
            type: "",
        },
        {
            id: "company",
            numeric: false,
            disablePadding: true,
            label: "Transaction ID",
            type: "",
        },
        {
            id: "paid",
            numeric: false,
            disablePadding: true,
            label: "Amount",
            type: "",
        },

        {
            id: "platform",
            numeric: false,
            disablePadding: true,
            label: "Date & Time",
            type: "",
        }
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort ${isSticky} `}>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function CampaignWallet(props) {

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        limit,
        page,
        sort_key,
        totalCount,
        totalPages,
        filters,
        startDate,
        search,
        campaignData,
        exploreCampaignDetails: listing,
    } = useSelector((state) => state.walletSlice);
    const debouncedSearch = useDebounce(search, 500);
    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateWallet({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllBrandForDropDown());
    };

    useEffect(() => {
        if (debouncedSearch)
            dispatch(getExploreCampaignDetails(id))
    }, [dispatch, debouncedSearch, id]);

    useEffect(() => {
        dispatch(getExploreCampaignDetails(id))
        return () => dispatch(resetWallet())
    }, []);

    const handleSearch = (event) => {
        if (event.target.value) {
            dispatch(updateWallet({ search: event.target.value }));
        } else {
            dispatch(updateWallet({ search: "" }));
            dispatch(getExploreCampaignDetails(id))
        }

    };

    const handleChangePerPage = (event, value) => {
        dispatch(updateWallet({ limit: event.target.value, page: 0 }));
        dispatch(getExploreCampaignDetails(id));
    };

    const handleChange = (event, value) => {
        dispatch(updateWallet({ page: value - 1 }));
        dispatch(getExploreCampaignDetails(id));
    };
    const handleSearchClear = () => {
        dispatch(updateWallet({ filters: { ...filters, name: "" } }));
        dispatch(getExploreCampaignDetails(id));
    };

    let dataFilter = startDate ? { startDate: startDate } : {};
    return (
        <>
            <Stack direction="row" alignItems="left" mb={2}>
                <Typography className="page-title capitalize-first-letter" variant="h1"> {campaignData?.name}</Typography>
                <Stack
                    className="backText"
                    direction={"row"}
                    alignItems={"left"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/wallet")}
                >
                    <Typography variant="h5" color={"primary"}>
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg> Back
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction={"row"} gap={2}>
                <div className="row">
                    <div className="col-12">
                        <div className="viewAmtBlcks">
                            <div className="amtblckItem">
                                <div><h4>&#x20B9;{formatPrice(campaignData?.budget) || 0}</h4>
                                    <p>Campaign Budget</p>
                                </div>
                            </div>
                            <div className="amtblckItem">
                                <div>
                                    <h4>&#x20B9;{formatPrice(campaignData?.remainingBudget) || 0}</h4>
                                    <p>Remaining Budget</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Stack>
            <div className="mt-4">
                <Typography className="mt-20" variant="h4">Payment History</Typography>
                <div className="card mt-20">
                    <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                        <SearchInput
                            className="inputSearch sContrl p-0"
                            value={search}
                            placeholder={"Search here..."}
                            handleSearch={handleSearch}
                            clearSearch={handleSearchClear}
                            startAdornment={
                                <InputAdornment className="overIcon" position="start"
                                    sx={{
                                        maxHeight: 'none',
                                        height: '38px',
                                        minWidth: '38px',
                                        marginRight: '0px'
                                    }}
                                >
                                    <Search />
                                </InputAdornment>
                            }
                        />
                        <div className="d-flex  gap-1" >
                            <span style={{ background: "white", borderRadius: 10 }}>
                                <DateRangePickerComponent text="Date Range" getSelectedDate={(dates) => {
                                    if (dates?.filter((v) => v)?.length === 2) {
                                        dispatch(updateWallet({ startDate: getFormatedDate(dates[0]), endDate: getFormatedDate(dates[1]) }));
                                        dispatch(getExploreCampaignDetails(id));
                                    }
                                }} dateReset={dataFilter} />
                            </span>
                            <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', position: "relative", top: "10px" }}
                                onClick={() => {
                                    dispatch(updateWallet({ startDate: "", endDate: "" }));
                                    dispatch(getExploreCampaignDetails(id));
                                }} />
                        </div>
                    </div>
                    <TableContainer sx={{ maxHeight: 550 }} >
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                // numSelected={selected.length}

                                order={order}
                                orderBy={sort_key}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                //rowCount={rows.length}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            //order={"desc"}
                            />
                            <TableBody>
                                {listing && listing?.length ? (
                                    listing?.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row?.id}
                                                className={"tableRow"}
                                                style={{ userSelect: "text" }}
                                            >
                                                <TableCell component="td" scope="row">
                                                    {row?.name || "N/A"}

                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {row?.txId || "N/A"}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    <div className="paymentDue">
                                                        ₹{formatPrice(row?.totalAmount) || "N/A"}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row?.createdAt ? getFormatedDate(row?.createdAt, "DD MMMM YYYY hh:mm A") : "N/A"}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <Stack
                    sx={{
                        background: 'transparent',
                      }}
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                            <Box className="d-flex align-items-center rowPerPage" gap={0}>
                                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                            </Box>
                            <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                                    {listing && listing.length > 0
                                        ? page === 0
                                            ? `0 - ${listing.length}`
                                            : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                            } `
                                        : "0 - 0"}{" "}
                                    &nbsp;of {totalCount} items
                                </Typography>
                                <Pagination

                                    count={Math.ceil(totalPages / limit) || 0}
                                    variant="outlined"
                                    onChange={handleChange}
                                    defaultPage={1} siblingCount={0} boundaryCount={1}
                                />
                            </Box>
                        </Box>
                    </Stack >
                </div>
            </div>
        </>
    );
}
