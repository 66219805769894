import {
  Search
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  InputAdornment,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import useDebounce from "../../../hooks/use.debounce";
import { getFormatedDate } from "../../../utils/common";
import { SUBSCRIPTION_STATUS } from "../../../utils/constant";
import { getAllBrandForDropDown } from "../../brands/action";
import { resetSubscription } from "../../subscriptions/subscriptionSlice";
import { getAllSubscriptions } from "../action";
import { updateNotification } from "../notificationSlice";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isSticky } = props;
  const createSortHandler =
    (property) => (event) => {
      if (property === "action") {
        return;
      }
      onRequestSort(event, property);
    };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Group Name",
      type: "",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Group Description",
      type: "",
    },
    {
      id: "noofUsers",
      numeric: false,
      disablePadding: true,
      label: "No. of Users",
      type: "",
    },

    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className={`hideActionSort ${isSticky} `}>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={false}
            onChange={() => { }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={null}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}


export default function GroupNotification(props) {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [open, setOpen] = useState(false);
  const [sticky, setSticky] = useState("");
  const [status, setStatus] = useState({ name: "", status: "", id: "" });
  const dispatch = useDispatch();
  const {
    limit,
    page,
    sort_key,
    totalCount,
    totalPages,
    filters,
    listing,
  } = useSelector((state) => state.notificationSlice);
  const debouncedSearch = useDebounce(filters.name, 500);
  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateNotification({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );
    dispatch(getAllBrandForDropDown());
  };

  useEffect(() => {
    if (debouncedSearch || filters.name == "")
      dispatch(getAllSubscriptions())
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(getAllSubscriptions())
    return () => dispatch(resetSubscription())
  }, []);

  const handleSearch = (event) => {
    //if (event.target.value)
    dispatch(updateNotification({ filters: { ...filters, name: event.target.value } }));
  };

  const handleSearchClear = () => {
    dispatch(updateNotification({ filters: { ...filters, name: "" } }));
    dispatch(getAllSubscriptions());
  };
  const tableRef = useRef();
  // useEffect(() => {
  //   const tableContainer = tableRef.current;
  //   const handleScroll = () => {

  //     if (tableContainer) {
  //       const { scrollTop, clientHeight, scrollHeight } = tableContainer;
  //       console.log("scroll", Math.ceil(scrollTop + clientHeight), scrollHeight - 1);
  //       if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
  //         dispatch(updateNotification({ limit: limit + 10, page: 0 }));
  //         dispatch(getAllSubscriptions())
  //       }
  //     }

  //   };

  //   tableContainer.addEventListener('scroll', handleScroll);

  //   return () => {
  //     tableContainer.removeEventListener('scroll', handleScroll);
  //   };
  // });
  // const isSticky = () => {
  //   const scrollTop = window.scrollY;
  //   const stickyClass = scrollTop >= 150 ? "is-sticky" : "";
  //   setSticky(stickyClass);
  //   console.log("stickyClass", stickyClass, scrollTop);
  // };
  // useEffect(() => {
  //   console.log("hello");
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // }, []);
  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1"> Notification Groups</Typography>
          <button className="btnPrimary"
            disabled={!states?.permissions?.subscriptions?.create}
            onClick={() => navigate("/subscription/create")}
          >
            Create Group
          </button>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            // overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            <div className='allFilter cmpyMgmt '>
              <div className="d-flex align-items-center gap-2" style={{ marginRight: 0 }}>

              </div>
            </div>
            <SearchInput
              className="inputSearch sContrl p-0"
              value={filters.name}
              placeholder={"Search here..."}
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
              startAdornment={
                <InputAdornment className="overIcon" position="start"
                  sx={{
                    maxHeight: 'none',
                    height: '38px',
                    minWidth: '38px',
                    marginRight: '0px'
                  }}
                >
                  <Search />
                </InputAdornment>
              }
            />

          </div>

          <Paper sx={{ width: '100%', height: "100%", }}>
            <TableContainer sx={{ maxHeight: 550 }} ref={tableRef}>
              <Table
                stickyHeader aria-label="sticky table"
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  isSticky={sticky}
                  order={order}
                  orderBy={sort_key}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  //rowCount={rows.length}
                  numSelected={0}
                  onSelectAllClick={function (

                  ) {
                    throw new Error("Function not implemented.");
                  }}
                //order={"desc"}
                />
                <TableBody>
                  {listing && listing?.length && false ? (
                    listing?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.id}
                          className={"tableRow"}
                          style={{ userSelect: "text" }}
                        >
                          <TableCell><Checkbox
                            checked={false}
                            onChange={() => { }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /></TableCell>
                          <TableCell component="td" scope="row">
                            <Link className="text-decoration-none" style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px' }} onClick={(e) => {
                              e.preventDefault();
                              navigate("/company/details/" + row.id)
                            }} >{row?.name || "N/A"}</Link>

                          </TableCell>

                          <TableCell align="left">
                            {row?.updatedDate ? getFormatedDate(row?.updatedDate, "DD MMMM YYYY") : "N/A"}

                          </TableCell>
                          <TableCell align="left">
                            {row.status ? <button className={row.status?.toLowerCase()}>{row.status || "N/A"}</button> : "N/A"}
                          </TableCell>
                          <TableCell sx={{ cursor: "pointer" }}>
                            <Stack direction={"row"}
                              sx={{
                                gap: '8px'
                              }}
                            >
                              <div className="actBtnGrps">
                                {states?.permissions?.subscriptions?.edit == 1 && row.status === SUBSCRIPTION_STATUS.DRAFT && <span className="editIcon" onClick={() => navigate(`/subscription/edit/${row.id}`)}>Edit</span>}
                                <span className="viewIcon" onClick={() => navigate("/subscription/details/" + row.id)}>View</span>
                                {row.status !== SUBSCRIPTION_STATUS.DISABLE && <>
                                  <span style={{ color: `${row.status === SUBSCRIPTION_STATUS.DRAFT ? "green" : "red"}` }} onClick={() => {
                                    setStatus({ name: row.name, status: row.status === SUBSCRIPTION_STATUS.DRAFT ? SUBSCRIPTION_STATUS.LIVE : SUBSCRIPTION_STATUS.DISABLE, id: row.id });
                                    setOpen(true)
                                  }}>{row.status === SUBSCRIPTION_STATUS.DRAFT ? "Activate" : "Disable"}</span></>}
                              </div>

                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow colSpan={10}>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                        <Typography
                          color="inherit"
                          variant="subtitle1"
                          alignItems={"center"}
                          component="h3"
                        >
                          No Data found!
                        </Typography>

                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </TableContainer>
            <hr />

          </Paper>
          <CommonModel
            open={open}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={""}
            dialogPara={`Are you sure, you want to ${status.status} this “${status.name}” plan ? `}
            nvYes={"Confirm"}
            nvCancel={"Cancel"}
            yesHandle={() => {

              setOpen(false);
            }}
          />
        </Box>




      </Stack>

    </>
  );
}
