
import { useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateRoute } from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Layout from "./layout";
import AddNewRoles from "./pages/addRoles";
import AdminAddUser from "./pages/adminAddUser";
import AdminChangePassword from "./pages/adminChangePassword";
import AdminEditUser from "./pages/adminEditUser";
import AdminUsers from "./pages/adminUsers";
import { default as AuditTrails } from "./pages/auditTrails";
import AddBrandForCompany from "./pages/brands/Add";
import BrandsForCompany from "./pages/brands/table";
import BudgetTarget from "./pages/campaigns/create/BudgetTarget";
import CampaignDates from "./pages/campaigns/create/CampaignDates";
import Info from "./pages/campaigns/create/Info";
import Preview from "./pages/campaigns/create/Preview";
import ProfilerScreener from "./pages/campaigns/create/ProfilerScreener";
import SearchInfluencer from "./pages/campaigns/create/SearchInfluencer";
import SubType from "./pages/campaigns/create/SubType";
import Type from "./pages/campaigns/create/Type";
import Review from "./pages/campaigns/create/review";
import { default as CampaignDetails, default as Details } from "./pages/campaigns/details";
import PartialCampaignDetails from "./pages/campaigns/details/partialDetails";
import Listing from "./pages/campaigns/listing/table";
import SendMoreInfluencer from "./pages/campaigns/myCampaigns/SendMoreInfluencers";
import ApprovedInfluencers from "./pages/campaigns/myCampaigns/approvedInfluencers";
import CampaignInfluencers from "./pages/campaigns/myCampaigns/campaignInfluencer";
import NegotiatedInfluencers from "./pages/campaigns/myCampaigns/negotiatedInfluencers";
import MyCampaigns from "./pages/campaigns/myCampaigns/table";
import AddEditCompany from "./pages/company/Add";
import CompanySignup from "./pages/company/CompanySignup";
import CompanyDetails from "./pages/company/details";
import { default as Brands, default as Companies } from "./pages/company/table";
import AddCompanyUser from "./pages/company/users/Add";
import Users from "./pages/company/users/table";
import CreateNewCampaign from "./pages/createCampaign";
import CreateInfluencer from "./pages/createInfluencer";
import CreatePassword from "./pages/createPassword";
import Dashboard from "./pages/dashboard";
import InfluencersListing from "./pages/dashboard/table";
import EditCampaign from "./pages/editCampaign";
import EditInfluencer from "./pages/editInfluencer";
import EditRole from "./pages/editRoles";
import Forgot from "./pages/forgotPassword";
import Login from "./pages/login";
import MyCampaign from "./pages/myCampaigns";
import GroupNotification from "./pages/notifications/group/table";
import TwoFactorAuth from "./pages/otp/towFactorAuth";
import Page404 from "./pages/page404";
import PaymentStatus from "./pages/payment";
import PrivacyAndPolicy from "./pages/privacy";
import ResetPassword from "./pages/resetPassword";
import RolesAndRights from "./pages/rolesAndRights";
import InfluencerSearch from "./pages/searchInfluencer";
import AddEditSubscriptions from "./pages/subscriptions/admin/Add";
import DetailsSubscriptions from "./pages/subscriptions/admin/details";
import EditSubscriptions from "./pages/subscriptions/admin/edit";
import Subscriptions from "./pages/subscriptions/admin/table";
import SubscriptionsCompany from "./pages/subscriptions/company";
import SubscriptionHistory from "./pages/subscriptions/company/history";
import ActiveSubscription from "./pages/subscriptions/company/subscription";
import SubscriptionsListing from "./pages/subscriptions/company/subscriptionListing";
import UserDetail from "./pages/userDetails";
import AllBrands from "./pages/users/allBrands";
import Contents from "./pages/users/contents";
import UserSocialDetails from "./pages/users/details";
import CreatorListing from "./pages/users/table";
import UserDeleted from "./pages/users/userDeleted";
import RoleView from "./pages/viewRoles";
import Wallet from "./pages/wallet";
import CampaignWallet from "./pages/wallet/campaignListing";
import "./utils/proptypes";
import { ROUTES } from "./utils/routes";

function App() {
  // useEffect(() => {
  //   getFirebaseToken().then((res) => {
  //     if (res) {
  //       localStorage.setItem("notificationToken", res);
  //     }
  //   });
  // }, []);
  const routes = useRoutes([
    { path: ROUTES.USER_DELETED, element: <UserDeleted /> },
    { path: ROUTES.LOGIN, element: <Login /> },
    { path: `${ROUTES.VERIFY_OTP}`, element: <TwoFactorAuth /> },
    { path: ROUTES.FORGOT_PASSWORD, element: <Forgot /> },
    { path: `${ROUTES.RESET_PASSWORD}/:token`, element: <ResetPassword /> },
    { path: ROUTES.CREATE_NEW_PASSWORD, element: <CreatePassword /> },
    // { path: ROUTES.SIGN_UP, element: <SignUp /> },
    { path: "*", element: <Page404 /> },
    { path: ROUTES.COMPANY_SIGNUP, element: <PublicRoute element={<CompanySignup />} /> },
    { path: ROUTES.SUBSCRIPTION_FOR_COMPANY, element: <SubscriptionsCompany /> },
    { path: `${ROUTES.ACTIVE_SUBSCRIPTION_DETAILS}/:id`, element: <SubscriptionsListing /> },
    { path: ROUTES.PAYMENT_STATUS, element: <PaymentStatus /> },
    {
      path: ROUTES.DASHBOARD,
      element: <Layout />,
      children: [
        { element: <PrivateRoute element={<Dashboard />} />, index: true },
        {
          path: ROUTES.USER_MANAGEMENT,
          element: (
            <PrivateRoute
              pageType={"users"}
              requiredPermissions={"view"}
              element={<CreatorListing />}
            />
          ),
        },
        {
          path: ROUTES.BRAND_MANAGEMENT,
          element: (
            <PrivateRoute
              pageType={"brand"}
              requiredPermissions={"view"}
              element={<Brands />}
            />
          ),
        },
        {
          path: ROUTES.CREATE_INFLUENCER,
          element: (
            <PrivateRoute
              pageType={"users"}
              requiredPermissions={"create"}
              element={<CreateInfluencer />}
            />
          ),
        },
        {
          path: `${ROUTES.EDIT_INFLUENCER}/:id`,
          element: (
            <PrivateRoute
              pageType={"users"}
              requiredPermissions={"edit"}
              element={<EditInfluencer />}
            />
          ),
        },
        {
          path: ROUTES.CAMPAIGN_MANAGEMENT,
          element: <PrivateRoute element={<Listing />} />,
        },
        {
          path: ROUTES.ADMIN_USERS,
          element: <PrivateRoute element={<AdminUsers />} />,
        },
        {
          path: ROUTES.ROLES_AND_RIGHTS,
          element: <PrivateRoute element={<RolesAndRights />} />,
        },
        {
          path: ROUTES.SUBSCRIPTION_MGT,
          element: <PrivateRoute element={<Subscriptions />} />,
        },
        {
          path: ROUTES.AUDIT_TRAILS,
          element: <PrivateRoute element={<AuditTrails />} />,
        },
        {
          path: `${ROUTES.USER_MANAGEMENT}${ROUTES.VERIFY_NEW_USER_DETAILS}/:id`,
          element: (
            <PrivateRoute
              pageType={"users"}
              requiredPermissions={"view"}
              element={<UserDetail />}
            />
          ),
        },
        {
          path: `${ROUTES.ADMIN_USERS}${ROUTES.EDIT}/:id`,
          element: <PrivateRoute element={<AdminEditUser />} />,
        },
        {
          path: `${ROUTES.ADMIN_USERS}${ROUTES.ADD}`,
          element: <PrivateRoute element={<AdminAddUser />} />,
        },
        {
          path: `${ROUTES.ADMIN_USERS}${ROUTES.CHANGE_PASSWORD}/:id`,
          element: <PrivateRoute element={<AdminChangePassword />} />,
        },
        { path: `${ROUTES.ROLES_AND_RIGHTS}${ROUTES.ADD}`, element: <PrivateRoute element={<AddNewRoles />} /> },
        { path: `${ROUTES.ROLES_AND_RIGHTS}${ROUTES.EDIT}/:id`, element: <PrivateRoute element={<EditRole />} /> },
        { path: `${ROUTES.ROLES_AND_RIGHTS}${ROUTES.VIEW}/:id`, element: <PrivateRoute element={<RoleView />} /> },
        { path: ROUTES.COMPANIES, element: <PrivateRoute element={<Companies />} /> },
        { path: ROUTES.COMPANY_ADD, element: <PrivateRoute element={<AddEditCompany />} /> },
        { path: `${ROUTES.COMPANY_EDITS}/:id`, element: <PrivateRoute element={<AddEditCompany />} /> },
        { path: ROUTES.CAMPAIGN_STEP1, element: <PrivateRoute element={<Type />} /> },
        { path: ROUTES.CAMPAIGN_STEP2, element: <PrivateRoute element={<SubType />} /> },
        { path: ROUTES.CAMPAIGN_STEP3, element: <PrivateRoute element={<Info />} /> },
        { path: ROUTES.CAMPAIGN_STEP4, element: <PrivateRoute element={<BudgetTarget />} /> },
        { path: ROUTES.CAMPAIGN_STEP5, element: <PrivateRoute element={<CampaignDates />} /> },
        { path: ROUTES.CAMPAIGN_STEP6, element: <PrivateRoute element={<ProfilerScreener />} /> },
        { path: ROUTES.CAMPAIGN_STEP7, element: <PrivateRoute element={<Preview />} /> },
        { path: `${ROUTES.CAMPAIGN_STEP7}/:id`, element: <PrivateRoute element={<Preview />} /> },
        { path: ROUTES.CAMPAIGN_REVIEW, element: <PrivateRoute element={<Review />} /> },
        { path: ROUTES.CAMPAIGNS, element: <PrivateRoute element={<Listing />} /> },
        { path: ROUTES.SEARCH_INFLUENCER, element: <PrivateRoute element={<SearchInfluencer />} /> },
        { path: ROUTES.BRANDS_FOR_COMPANY, element: <PrivateRoute element={<BrandsForCompany />} /> },
        { path: ROUTES.ADD_BRANDS_FOR_COMPANY, element: <PrivateRoute element={<AddBrandForCompany />} /> },
        { path: `${ROUTES.BRANDS_FOR_COMPANY}/:id`, element: <PrivateRoute element={<AddBrandForCompany />} /> },
        { path: ROUTES.MY_CAMPAIGNS, element: <PrivateRoute element={<MyCampaigns />} /> },
        { path: `${ROUTES.USER_MANAGEMENT_DETAILS}/:id`, element: <PrivateRoute element={<UserSocialDetails />} /> },

        { path: `${ROUTES.MY_CAMPAIGN_INFLUENCER}/:id`, element: <PrivateRoute element={<CampaignInfluencers />} /> },
        { path: `${ROUTES.MY_CAMPAIGN_APPROVED_INFLUENCER}/:id`, element: <PrivateRoute element={<ApprovedInfluencers />} /> },
        { path: `${ROUTES.NEGOTIATED_INFLUENCER}/:id`, element: <PrivateRoute element={<NegotiatedInfluencers />} /> },
        {
          path: `${ROUTES.ROLES_AND_RIGHTS}${ROUTES.ADD}`,
          element: <PrivateRoute element={<AddNewRoles />} />,
        },
        {
          path: `${ROUTES.ROLES_AND_RIGHTS}${ROUTES.EDIT}/:id`,
          element: <PrivateRoute element={<EditRole />} />,
        },
        {
          path: `${ROUTES.ROLES_AND_RIGHTS}${ROUTES.VIEW}/:id`,
          element: <PrivateRoute element={<RoleView />} />,
        },
        {
          path: ROUTES.CREATE_CAMPAIGN,
          element: <PrivateRoute element={<CreateNewCampaign />} />,
        },
        {
          path: ROUTES.EDIT_CAMPAIGN,
          element: <PrivateRoute element={<EditCampaign />} />,
        },
        {
          path: ROUTES.MY_CAMPAIGN,
          element: <PrivateRoute element={<MyCampaign />} />,
        },
        { path: `${ROUTES.CAMPAIGNS_DETAILS}/:id`, element: <PrivateRoute element={<Details />} /> },
        { path: `${ROUTES.COMPANY_DETAILS}/:id`, element: <PrivateRoute element={<CompanyDetails />} /> },
        { path: `${ROUTES.CAMPAIGN_DETAILS}/:id`, element: <PrivateRoute element={<CampaignDetails />} /> },
        { path: `${ROUTES.COMING_SOON}`, element: <PrivateRoute element={<AuditTrails />} /> },
        {
          path: ROUTES.SEARCH_INFLUENCER_BY_COMPANY,
          element: <PrivateRoute element={<InfluencerSearch />} />,
        },
        { path: `${ROUTES.COMPANY_PROFILE_EDIT}/:id`, element: <PrivateRoute element={<AddEditCompany />} /> },
        { path: `${ROUTES.PRIVACY_POLICY}`, element: <PrivateRoute element={<PrivacyAndPolicy />} /> },
        { path: `${ROUTES.INFLUENCER_LISTING}`, element: <PrivateRoute element={<InfluencersListing />} /> },
        { path: `${ROUTES.PARTIAL_CAMPAIGN_DETAILS}/:id`, element: <PrivateRoute element={<PartialCampaignDetails />} /> },
        { path: `${ROUTES.SEND_MORE_INFLUENCER}/:id`, element: <PrivateRoute element={<SendMoreInfluencer />} /> },
        { path: `${ROUTES.CREATOR_MANAGEMENT}`, element: <PrivateRoute element={<CreatorListing />} /> },
        { path: `${ROUTES.CREATOR_CONTENT_LIST}/:id/:user_id`, element: <PrivateRoute element={<Contents />} /> },
        { path: `${ROUTES.USER_BRANDS_LIST}/:id/:user_id`, element: <PrivateRoute element={<AllBrands />} /> },
        { path: `${ROUTES.ADD_SUBSCRIPTION}`, element: <PrivateRoute element={<AddEditSubscriptions />} /> },
        { path: `${ROUTES.EDIT_SUBSCRIPTION}/:id`, element: <PrivateRoute element={<EditSubscriptions />} /> },
        { path: `${ROUTES.DETAILS_SUBSCRIPTION}/:id`, element: <PrivateRoute element={<DetailsSubscriptions />} /> },
        { path: `${ROUTES.ACTIVE_SUBSCRIPTION}`, element: <PrivateRoute element={<ActiveSubscription />} /> },
        { path: `${ROUTES.SUBSCRIPTION_HISTORY}`, element: <PrivateRoute element={<SubscriptionHistory />} /> },
        { path: `${ROUTES.COMPANY_USERS}`, element: <PrivateRoute element={<Users />} /> },
        { path: `${ROUTES.COMPANY_USER_ADD}`, element: <PrivateRoute element={<AddCompanyUser />} /> },
        { path: `${ROUTES.COMPANY_USER_EDIT}/:id`, element: <PrivateRoute element={<AddCompanyUser />} /> },
        { path: `${ROUTES.WALLET}`, element: <PrivateRoute element={<Wallet />} /> },
        { path: `${ROUTES.CAMPAIGN_WALLET}/:id`, element: <PrivateRoute element={<CampaignWallet />} /> },
        { path: `${ROUTES.GROUP_NOTIFICATION}`, element: <PrivateRoute element={<GroupNotification />} /> },

      ],
    },
  ]);
  return routes;
}

export default App;
