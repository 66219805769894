import {
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";

import Box from "@mui/material/Box";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from "../components/snackbar";
import { useAddRolesAndPermissionsMutation } from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { ROUTES } from "../utils/routes";
import { roleValidationSchema } from "../utils/schema";

const AddNewRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "success",
    open: false,
  });
  const [assignRights, setAssignRights] = useState([]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      roleName: "",
      description: "",
    },
    validationSchema: roleValidationSchema,
    onSubmit: async (values) => {
      if (values.roleName && values.description && assignRights) {
        const allTitles = [
          "dashboard",
          "brand",
          "company",
          "users",
          "adminUsers",
          "campaign",
          "subscription",
          "auditTrail",
          "roleAndRights",
          "privacyPolicy",
          "subscriptions"
        ];
        const updatedRights = [...assignRights];



        const existingTitles = updatedRights.map((item) => item.type);

        allTitles.forEach((title) => {
          if (!existingTitles.includes(title)) {
            updatedRights.push({ type: title, rights: [] });
          }
        });

        const payload = {
          roleName: values.roleName,
          description: values.description,
          assignRights: updatedRights,
        };
        await addRolesAndPermissions({ ...payload, userType: "admin" });
      }
    },
  });

  const [addRolesAndPermissions, { isLoading, data: userData }] =
    useAddRolesAndPermissionsMutation();

  useEffect(() => {
    if (userData?.status == "SUCCESS") {
      dispatch(updateCommonData({
        message: "Role added successfully",
        title: "Success",
        img: "/success.png",
        status: true
      }));
      navigate(ROUTES.ROLES_AND_RIGHTS);

    }
    if (userData?.status == "FAILURE") {
      dispatch(updateCommonData({
        message: userData.message || "Api error",
        title: "Failed",
        img: "/failed.png",
        status: true
      }));

    }
  }, [isLoading]);

  const handleRightsChange = (type, right, moduleName) => {
    const updatedRights = [...assignRights];
    const index = updatedRights.findIndex((item) => item.type === moduleName);

    if (index !== -1) {
      const existingRights = updatedRights[index].rights;
      const rightIndex = existingRights.indexOf(right);
      if (rightIndex !== -1) {
        existingRights.splice(rightIndex, 1);
      } else {
        existingRights.push(right);
      }
      updatedRights[index].rights = existingRights;
    } else {
      updatedRights.push({ type: moduleName, rights: [right] });
    }

    setAssignRights(updatedRights);
  };

  return (
    <>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack direction="row" alignItems="center" spacing={3} mb={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Add New Role
          </Typography>
          <Stack
            className="backText"
            direction="row"
            gap={0.5}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={handleBackClick}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography variant="h5" color="primary">
              Back
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="p-4">
            <div className="row flex-column gap-3">
              <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <FormLabel required>Role Name</FormLabel>
                <OutlinedInput
                  className="w-100"
                  type="text"
                  name="roleName"
                  onChange={formik.handleChange}
                  value={formik.values.roleName}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.roleName && Boolean(formik.errors.roleName)
                  }
                  helperText={formik.touched.roleName && formik.errors.roleName}
                />
                {formik.touched.roleName && (
                  <FormHelperText className="Mui-error" variant="standard" error>
                    {formik.errors.roleName}
                  </FormHelperText>
                )}
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="textField">
                  <FormLabel required>Role Description</FormLabel>
                  <OutlinedInput
                    className="w-100"
                    type="text"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    multiline={true}
                    style={{ padding: '0px', minHeight: '70px' }}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                  {formik.touched.description && (
                    <FormHelperText className="Mui-error" variant="standard" error>
                      {formik.errors.description}
                    </FormHelperText>
                  )}
                </div>
              </div>

            </div>
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4" className="subtitle2 px-4 py-4" style={{ background: '#f5f5f5' }}>
              Assign Rights
            </Typography>
            <div className="scrollRoles">
              <div className="assRight">
                <RightsSection
                  title="Dashboard"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="dashboard"
                />
                <RightsSection
                  title="Brand"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="brand"
                />
                <RightsSection
                  title="Company"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="company"
                />
                <RightsSection
                  title="Users"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="users"
                />
                <RightsSection
                  title="Admin Users"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="adminUsers"
                />
                <RightsSection
                  title="Campaign Mgt"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="campaign"
                />
                <RightsSection
                  title="Subscription"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="subscription"
                />
                <RightsSection
                  title="Audit Trail"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="auditTrail"
                />
                <RightsSection
                  title="Role and Rights"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="roleAndRights"
                />
                <RightsSection
                  title="Privacy and Policy"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="privacyPolicy"
                />
                <RightsSection
                  title="Subscriptions"
                  rights={["create", "edit", "delete", "view"]}
                  onRightsChange={handleRightsChange}
                  moduleName="subscriptions"
                />
              </div>
            </div>
          </div>
          <hr />
          <Stack direction="row" justifyContent="end" gap={1} p={3}>
            <button className="btnSecondary" onClick={handleCancelClick}>Cancel</button>
            <button type="submit" className="btnPrimary">Save Role</button>
          </Stack>
        </form>
      </Box>
    </>
  );
};

const RightsSection = ({ title, rights, onRightsChange, moduleName }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body1" style={{ minWidth: "200px" }}>
        {title}
      </Typography>
      <div style={{ display: "flex", gap: "30px" }}>
        {rights.map((right) => (
          <div key={right} className="form-check">
            <label className="form-check-label" htmlFor={`${title}-${right}`}>
              {right.capitalize()}
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id={`${title}-${right}`}
              onChange={() => onRightsChange(title.toLowerCase(), right, moduleName)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddNewRoles;
