import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "desc",
  sort_key: "id",
  search: "",
  totalCount: 0,
  totalPages: 0,
  details: [],
  userDetails: {},
  detailLoader: false,
  modelOpen: false,
  brandCount: 0,
  status: "",
  listing: [],
  filters: {},
  userContents: [],
  allbrands: [],
  brandsPosts: [],
  brandPostlimit: 10,
  brandPostpage: 0,
  emailDisplay: "",
  emailMsgDisplay: "",
  phoneDisplay: "",
  phoneMsgDisplay: "",
  cityStateList: [],
  citySearch: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateUserNew: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUser: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser, resetUser, updateUserNew } =
  userSlice.actions;

export default userSlice.reducer;
