import { Avatar, Box, Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import CommonModel from "../../../components/dialog/Common";
import { getCompanyid, getFormatedDate, getFormatedNumber } from "../../../utils/common";
import { getAllBrandForDropDown } from "../../brands/action";
import { cancelRecurringSubscription, getActivePlanDetails } from "../action";
import { resetSubscription, updateSubscription } from "../subscriptionSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: '1px solid #ddd',
}));
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "id",
            numeric: false,
            disablePadding: true,
            label: "S. no",
            type: "",
        },
        {
            id: "username",
            numeric: false,
            disablePadding: true,
            label: "Username",
            type: "",
        },
        {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            type: "",
        },
        {
            id: "phone",
            numeric: false,
            disablePadding: true,
            label: "Phone",
            type: "",
        },
        {
            id: "searches",
            numeric: false,
            disablePadding: true,
            label: "Searches",
            type: "",
        },

        {
            id: "creditused",
            numeric: false,
            disablePadding: true,
            label: "Credit used",
            type: "",
        }
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort `}>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


const ActiveSubscription = () => {

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [open, setOpen] = useState(false);


    const dispatch = useDispatch();
    const {
        sort_key,
        planDetails,
        recurringEndDate
    } = useSelector((state) => state.subscriptionSlice);
    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateSubscription({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllBrandForDropDown());
    };

    useEffect(() => {

        dispatch(getActivePlanDetails());
        return () => dispatch(resetSubscription())
    }, []);

    const CampaignDetailsToolTip = (newData) => {


        let data = newData.data;
        return <Table aria-label="simple table" sx={{ boxShadow: 'none', border: 'none' }}>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ border: '1px solid #ddd' }}  >Paid</TableCell>
                    <TableCell sx={{ border: '1px solid #ddd' }}  >Barter</TableCell>
                    <TableCell sx={{ border: '1px solid #ddd' }}  >Affiliate</TableCell>
                    <TableCell sx={{ border: '1px solid #ddd' }}  >Performance</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell sx={{ border: '1px solid #ddd' }} component="th" scope="row">
                        {getFormatedNumber(data?.usePaid)}/{getFormatedNumber(data?.actualPaid)}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #ddd' }} component="th" scope="row">
                        {getFormatedNumber(data?.useBarter)}/{getFormatedNumber(data?.actualBarter)}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #ddd' }} component="th" scope="row">
                        {getFormatedNumber(data?.useAffiliate)}/{getFormatedNumber(data?.actualAffiliate)}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #ddd' }} component="th" scope="row">
                        {getFormatedNumber(data?.usePerformance)}/{getFormatedNumber(data?.actualPerformance)}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>

    }
    return <>
        <Stack
            className="mainTitle flex-wrap mb-3"
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}

            sx={{
                minHeight: '42px'
            }}
        >
            <Typography className="page-title" variant="h1">Active Plan</Typography>
            <Link to={`/subscription/active/details/${getCompanyid()}`} className="btnPrimary text-decoration-none"  >Upgrade Plan</Link>
        </Stack>

        <div className="row">
            {recurringEndDate &&
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="recurrInfo">
                        <div>
                            <h4>You have a recurring subscription set.</h4>
                            <p>Next billing date : <span>{getFormatedDate(planDetails?.recurringEndDate?.nextbillingDate, "DD MMMM YYYY")}</span></p>
                        </div>
                        <button className="btnDanger" onClick={() => setOpen(true)} >
                            Cancel
                        </button>
                    </div>
                </div>}

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="actItem" style={{ position: 'relative' }}>
                    {recurringEndDate && <Chip className="btnSmall" label="Recurring"
                        sx={{
                            background: '#22c55e !important',
                            borderRadius: '5px',
                            lineHeight: '24px',
                            position: 'absolute',
                            top: '10px',
                            right: '10px'
                        }}
                    />}

                    <img src="/premiumgold.png" alt="Premiumgold" />
                    <h4>{planDetails?.basicInfo?.name || "N/A"}</h4>
                    <p>Active Plan</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">

                <div className="actItem">

                    <img src="/credit.png" alt={planDetails?.basicInfo?.name?.replace(",", "") || "N/A"} />
                    <h4>
                        {(getFormatedNumber(planDetails?.basicInfo?.useSearchView) + getFormatedNumber(planDetails?.basicInfo?.useProfileView) + getFormatedNumber(planDetails?.basicInfo?.usePhoneNumber) + getFormatedNumber(planDetails?.basicInfo?.useEmail) + getFormatedNumber(planDetails?.basicInfo?.useInfluencerApproved) + getFormatedNumber(planDetails?.basicInfo?.usePaid) + getFormatedNumber(planDetails?.basicInfo?.useBarter) + getFormatedNumber(planDetails?.basicInfo?.useAffiliate) + getFormatedNumber(planDetails?.basicInfo?.usePerformance))}/{(getFormatedNumber(planDetails?.basicInfo?.actualSearchView) + getFormatedNumber(planDetails?.basicInfo?.actualProfileView) + getFormatedNumber(planDetails?.basicInfo?.actualPhoneNumber) + getFormatedNumber(planDetails?.basicInfo?.actualEmail) + getFormatedNumber(planDetails?.basicInfo?.actualInfluencerApproved) + getFormatedNumber(planDetails?.basicInfo?.actualPaid) + getFormatedNumber(planDetails?.basicInfo?.actualBarter) + getFormatedNumber(planDetails?.basicInfo?.actualAffiliate) + getFormatedNumber(planDetails?.basicInfo?.actualPerformance))}
                    </h4>
                    <p>Credit Usage</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="actItem subsTooltip">
                    <Tooltip componentsProps={{
                        tooltip: {
                            sx: {
                                padding:'0px !important', // Override tooltip background color
                                background:'transparent !important'
                            },
                        },
                    }} title={<CampaignDetailsToolTip data={planDetails?.basicInfo} />} placement="top" arrow sx={{ cursor: "pointer" }}>
                        <img src="/campaignlive.png" alt="Premiumgold" />
                        <h4>
                            {getFormatedNumber(planDetails?.basicInfo?.usePaid) + getFormatedNumber(planDetails?.basicInfo?.useBarter) + getFormatedNumber(planDetails?.basicInfo?.useAffiliate) + getFormatedNumber(planDetails?.basicInfo?.usePerformance)}/{(getFormatedNumber(planDetails?.basicInfo?.actualPaid) + getFormatedNumber(planDetails?.basicInfo?.actualBarter) + getFormatedNumber(planDetails?.basicInfo?.actualAffiliate) + getFormatedNumber(planDetails?.basicInfo?.actualPerformance))}
                        </h4>
                        <p>Campaigns Live</p>
                    </Tooltip>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="actItem">
                    <img src="/approved_influencer.png" alt="Premiumgold" />
                    <h4>{getFormatedNumber(planDetails?.basicInfo?.useInfluencerApproved)}/ {getFormatedNumber(planDetails?.basicInfo?.actualInfluencerApproved)}</h4>
                    <p>Approved Influencers</p>
                </div>
            </div>
        </div>
        <div className="card mt-4">
            <div className="p-3">
                <Typography variant="h4">
                    Unique User Logins : <span style={{ color: '#6C7692' }}>{getFormatedNumber(planDetails?.activeUsers)}/{getFormatedNumber(planDetails?.basicInfo?.actualUniqueUsersLogin)}</span>
                </Typography>
            </div>
            <TableContainer sx={{ maxHeight: 350 }}>
                <Table
                    stickyHeader aria-label="sticky table"
                >
                    <EnhancedTableHead
                        // numSelected={selected.length}

                        order={order}
                        orderBy={sort_key}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        //rowCount={rows.length}
                        numSelected={0}
                        onSelectAllClick={function (

                        ) {
                            throw new Error("Function not implemented.");
                        }}
                    //order={"desc"}
                    />
                    <TableBody>
                        {planDetails?.userInfo?.length > 0 ? (
                            planDetails?.userInfo?.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row?.id}
                                        className={"tableRow"}
                                        style={{ userSelect: "text", background: "" }}
                                    >
                                        <TableCell component="td" scope="row" >
                                            {++index}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            <Stack direction={"row"} >
                                                <Avatar
                                                    alt={row?.contactPersonName}
                                                    src={row.logo}
                                                    sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                                                />
                                                <span className="text-decoration-none text-capitalize" style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px', marginLeft: '10px', marginTop: "4px" }}>{row?.contactPersonName || "N/A"}</span>
                                            </Stack>
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {row?.email}
                                        </TableCell>

                                        <TableCell align="left">
                                            {row?.phoneNumber || "N/A"}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.searchView || 0}
                                        </TableCell>
                                        <TableCell align="left">
                                            {getFormatedNumber(row?.emailView) + getFormatedNumber(row?.searchView) + getFormatedNumber(row?.profileView) + getFormatedNumber(row?.usephoneNumber) + getFormatedNumber(row?.uniqueUsersLogin) + getFormatedNumber(row?.influencerApproved) + getFormatedNumber(row?.paid) + getFormatedNumber(row?.barter) + getFormatedNumber(row?.affiliate) + getFormatedNumber(row?.performance)}
                                        </TableCell>

                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow colSpan={10}>
                                <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                    <Typography
                                        color="inherit"
                                        variant="subtitle1"
                                        alignItems={"center"}
                                        component="h3"
                                    >
                                        No Data found!
                                    </Typography>

                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
        <CommonModel
            open={open}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={"Cancel recurring subscription"}
            dialogPara={`Are you sure, you want to cancel the recurring subscription?`}
            nvCancel={"Cancel"}
            nvYes={"Yes"}
            yesHandle={() => {
                dispatch(cancelRecurringSubscription());
                setOpen(false)
            }}
        />
    </>
}

export default ActiveSubscription